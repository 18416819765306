import { CountdownCircleTimer } from "react-countdown-circle-timer"
import { Typography } from "@mui/material"

const Timer = ({duration = 0, initialRemainingTime = 0, inTime = true, isPlaying = true}) => {
    const colorsTime = duration >= 300 ? [duration, 300, 180, 60, 30, 0] : [duration, 10, 0]
    const colors = duration >= 300
        ? ['#036908', '#35c53c', '#1565c0', '#efc536', '#ef8067', '#ee3b13']
        : ['#35c53c', '#ef8067', '#ee3b13']

    return (
        <CountdownCircleTimer
            isPlaying={isPlaying}
            initialRemainingTime={initialRemainingTime}
            duration={duration}
            size={65}
            colors={colors}
            colorsTime={colorsTime}
            strokeWidth={8}
        >
            {
                ({ remainingTime }) => {
                    const minutes = Math.floor(remainingTime / 60)
                    const seconds = remainingTime % 60

                    return <Typography
                        variant={"caption"}
                        fontSize={16}
                        fontWeight={600}
                        sx={{color: inTime ? "info.main" : "error.main"}}
                    >
                        {`${minutes}:${seconds}`}
                    </Typography>
                }
            }
        </CountdownCircleTimer>
    )
}

export default Timer