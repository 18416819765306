import { gql } from "@apollo/client"

//all users
export const LOAD_ALL_USERS = gql`
    query allUsers {
        allUsers {
            edges {
                node {
                    id
                    username
                    isStaff
                    isSuperuser
                    isVerified
                    client {
                        id
                        nextPayment
                        paymentType
                    }
                    isActivePayment
                }
            }
        }
    }`