import React from "react"

//material
import {
    Box, Button,
    Card,
    CardContent,
    Grid, TextField
} from "@mui/material"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { LoadingButton } from "@mui/lab"

//apollo
import { Mutation } from "@apollo/client/react/components"
import { SEND_PASSWORD_RESET_EMAIL } from "../../queries/auth/userQueries"

//formik
import { Formik } from "formik"
import * as Yup from "yup"

//router
import { useNavigate } from "react-router-dom"

//notification
import { ErrorNotification, SuccessNotification } from "../../components/Notifications/Notifications"


export default function RecoverPasswordPage(props) {
    const navigate = useNavigate()

    const notifyError = message => ErrorNotification(message)
    const notifySuccess = message => SuccessNotification(message)

    const [isRequested, setIsRequested] = React.useState(null)
    const [message, setMessage] = React.useState("")

    const validationSchema = Yup.object({
        email: Yup.string()
            .required('field required')
            .email('The email address is not valid')
    })

    const TableHeaderBar = () => {
        return (
            <AppBar position="static">
                <Toolbar disableGutters sx={{p: 1, display: "flex", alignItems: "center", justifyContent: 'space-between'}}>
                    <Typography
                        variant="h6"
                        noWrap
                        sx={{
                            mr: 2,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        Recover password
                    </Typography>
                </Toolbar>
            </AppBar>
        )
    }

    function handleClickAfterRequest(formik) {
        if (isRequested) {
            navigate("/")
        }
        if (isRequested === false) {
            setIsRequested(null)
            setMessage("")
            formik.resetForm()
        }
    }

    return (
        <Box sx={{width: "100%", mt: 3, display: "flex", alignItems: "center", justifyContent: "center"}}>
            <Card variant={"elevation"} sx={{width: {xs: 1, sm: 1, md: 3/4, lg: 1/2, xl: 1/3}}}>
                <TableHeaderBar/>
                <CardContent>
                    <Grid container justifyContent={"center"}>
                        <Mutation mutation={SEND_PASSWORD_RESET_EMAIL}>
                            {
                                (register, { loading, error, data}) => (
                                    <Formik
                                        initialValues={
                                            {
                                                email: ''
                                            }
                                        }
                                        validationSchema={validationSchema}
                                        onSubmit={(data, { setErrors }) => {
                                            register({
                                                variables: {
                                                    email: data.email
                                                }
                                            }).then(
                                                (response) => {
                                                    if (response.data.sendPasswordResetEmail.success) {
                                                        setIsRequested(true)
                                                        setMessage("Done")
                                                        notifySuccess("An email has been send to your mail inbox, please check it")
                                                    } else {
                                                        setIsRequested(false)
                                                        setMessage("Try again")
                                                        notifyError("Request password reset failed, try again")
                                                    }
                                                }
                                            ).catch(() => {
                                                setIsRequested(false)
                                                setMessage("Request password reset failed, try again")
                                            })
                                        }}
                                    >
                                        {
                                            formik => (
                                                <form onSubmit={formik.handleSubmit}>
                                                    <Grid container spacing={3}>
                                                        <Grid container item xs={12} spacing={2}>
                                                            <Grid item xs={12}>
                                                                <Typography variant={"body1"}>
                                                                    Use your account email to recover your password.
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    name="email"
                                                                    label="Email"
                                                                    size={"small"}
                                                                    fullWidth
                                                                    variant={"filled"}
                                                                    {...formik.getFieldProps("email")}
                                                                    error={formik.errors.email && formik.touched.email}
                                                                    helperText={(formik.errors.email && formik.touched.email) && formik.errors.email}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        {
                                                            isRequested === null
                                                            && <Grid container item xs={12} justifyContent={"flex-end"}>
                                                                <Button
                                                                    color={"secondary"}
                                                                    variant={"contained"}
                                                                    size={"small"}
                                                                    sx={{m: 1}}
                                                                    onClick={() => navigate("/")}
                                                                >
                                                                    Cancel
                                                                </Button>

                                                                <LoadingButton
                                                                    type={"submit"}
                                                                    color={"primary"}
                                                                    variant={"contained"}
                                                                    size={"small"}
                                                                    sx={{m: 1}}
                                                                    disabled={!formik.isValid}
                                                                    loading={loading}
                                                                >
                                                                    Request
                                                                </LoadingButton>
                                                            </Grid>
                                                        }

                                                        {
                                                            isRequested !== null
                                                            && <Grid container item xs={12} justifyContent={"center"}>
                                                                <Button
                                                                    color={isRequested ? "primary" : "error"}
                                                                    variant={"outlined"}
                                                                    size={"small"}
                                                                    onClick={() => handleClickAfterRequest(formik)}
                                                                >
                                                                    {message}
                                                                </Button>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </form>
                                            )
                                        }
                                    </Formik>
                                )
                            }
                        </Mutation>
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    )
}