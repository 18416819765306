import React from "react"
import { NavLink } from "react-router-dom"

import { Button, Grid, Fade } from "@mui/material"

//cookies
import Cookies from "js-cookie"

//assets
import cookiesAgreementStyle from "../../assets/styles/cookiesAgreementStyle"

export default function CookiesAgreement(props) {
    const [cookiesAgreementAccept, setCookiesAgreementAccept] = React.useState(!!Cookies.get("cookiesAgree"))

    function handleCookiesAccept() {
        setCookiesAgreementAccept(true)
        Cookies.set("cookiesAgree", true, { expires: 365 })
    }

    if (!cookiesAgreementAccept) {
        return (
            <Fade in={!cookiesAgreementAccept} timeout={1500}>
                <Grid container sx={cookiesAgreementStyle.container}>
                    <Grid container sx={cookiesAgreementStyle.content}>
                        <Grid item xs={12}>
                            This site uses cookies to remember information about your visit. <br />
                            You can see our cookies policy section in
                            <NavLink to={"#"} style={{ color: "white", fontWeight: "600" }}>
                                {" "}
                                Privacy Policy{" "}
                            </NavLink>
                            page.
                            <br />
                            If you choose to use our services we infer that you agree our policy. <br />
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                size={"small"}
                                variant={"outlined"}
                                onClick={handleCookiesAccept}
                                style={{ color: "#fff", borderColor: "#fff" }}
                            >
                                Accept
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Fade>
        )
    }
    return null
}
