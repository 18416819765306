import * as React from "react"

//material
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { Button, Card, CardContent, Chip, Grid, MenuItem, TextField } from "@mui/material"
import { LoadingButton } from "@mui/lab"

//formik
import { Formik } from "formik"
import * as Yup from "yup"

//router
import { useLocation, useNavigate } from "react-router-dom"

//components
import { ErrorNotification, SuccessNotification } from "../../../components/Notifications/Notifications"

//apollo
import { useMutation, useQuery } from "@apollo/client"
import {
    CREATE_QUESTIONNAIRE,
    LOAD_ALL_QUESTIONNAIRES,
    UPDATE_QUESTIONNAIRE,
    ALL_QUESTIONS,
    ALL_EXAM_PURPUSES,
} from "../../../queries/admin/questionnaireQueries"
import { LOAD_ALL_TOPICS } from "../../../queries/admin/topicQueries"

//utils
import { getFormErrors } from "../../../utils/errorsUtils"

//styles
import quizLayoutStyles from "../../../assets/styles/quizLayoutStyles"

//drag list
import QuestionnaireQuestionSelect from "./components/QuestionnaireQuestionSelect"
import QuestionnaireQuestionsRearrange from "./components/QuestionnaireQuestionsRearrange"

export default function AdminQuestionnaireForm() {
    const navigate = useNavigate()
    const location = useLocation()

    const updateItem = location.state?.updateItem

    const notifySuccess = (message) => SuccessNotification(message)
    const notifyError = (message) => ErrorNotification(message)

    const successForm = () => {
        updateItem ? notifySuccess("Questionnaire updated") : notifySuccess("Questionnaire added")
        navigate("/quiz/manage-questionnaire")
    }

    const [createQuestionnaire, { loading: loadingCreate }] = useMutation(CREATE_QUESTIONNAIRE, {
        refetchQueries: [LOAD_ALL_QUESTIONNAIRES],
    })
    const [updateQuestionnaire, { loading: loadingUpdate }] = useMutation(UPDATE_QUESTIONNAIRE, {
        refetchQueries: [LOAD_ALL_QUESTIONNAIRES],
    })

    const FormHeaderBar = () => {
        return (
            <AppBar position="static" color={"transparent"}>
                <Toolbar disableGutters sx={quizLayoutStyles.componentHeaderToolbar}>
                    <Box>
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{
                                mr: 2,
                                fontFamily: "monospace",
                                fontWeight: 700,
                                letterSpacing: ".3rem",
                                color: "inherit",
                                textDecoration: "none",
                            }}
                        >
                            {updateItem ? "Edit questionnaire" : "Create questionnaire"}
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
        )
    }

    const [topic, setTopic] = React.useState("")
    const [description, setDescription] = React.useState("")
    const [time, setTime] = React.useState("")
    const [questions, setQuestions] = React.useState([])
    const [examPurpose, setExamPurpose] = React.useState([])

    const [topicOptions, setTopicOptions] = React.useState([])
    useQuery(LOAD_ALL_TOPICS, {
        onCompleted: (data) => {
            if (data?.allTopics?.edges?.length) {
                setTopicOptions(data.allTopics.edges)
            }
        },
    })

    const [questionOptions, setQuestionOptions] = React.useState([])
    useQuery(ALL_QUESTIONS, {
        onCompleted: (data) => {
            if (data?.allQuestions?.edges?.length) {
                setQuestionOptions(data.allQuestions.edges)
            }
        },
    })

    const [examPurposeOptions, setExamPurposeOptions] = React.useState([])
    useQuery(ALL_EXAM_PURPUSES, {
        onCompleted: (data) => {
            if (data?.allExamPurposes?.edges?.length) {
                setExamPurposeOptions(data.allExamPurposes.edges)
            }
        },
    })

    //formik
    const initialValues = {
        topic: topic,
        description: description,
        time: time,
        questions: questions.map((item) => item.node.id),
        examPurpose: examPurpose,
    }

    const validationSchema = Yup.object({
        topic: Yup.object().required("field required"),
        description: Yup.string().required("field required"),
        time: Yup.number().integer("seconds field must be an integer number").required("field required"),
        questions: Yup.array().of(Yup.string()).min(1),
        examPurpose: Yup.array().of(Yup.string()).min(1),
    })

    React.useEffect(() => {
        if (updateItem) {
            if (updateItem.description) {
                setDescription(updateItem.description)
            }

            if (updateItem.time) {
                setTime(updateItem.time)
            }
        }
    }, [updateItem])

    React.useEffect(() => {
        if (updateItem && topicOptions.length) {
            let itemIndex = topicOptions.findIndex((item) => item.node.id === updateItem.topic.id)
            if (itemIndex > -1) {
                setTopic(topicOptions[itemIndex])
            }
        }
    }, [updateItem, topicOptions])

    React.useEffect(() => {
        if (updateItem?.questionnaireQuestionnaireQuestion?.edges?.length > 0 && questionOptions.length > 0) {
            let questions = []
            updateItem.questionnaireQuestionnaireQuestion.edges.forEach((item) => {
                let itemIndex = questionOptions.findIndex(
                    (questionOption) => questionOption.node.id === item.node.question.id
                )
                if (itemIndex > -1) {
                    questions.push(questionOptions[itemIndex])
                }
            })
            setQuestions(questions)
        }
    }, [updateItem, topicOptions, questionOptions])

    React.useEffect(() => {
        if (updateItem && examPurposeOptions.length) {
            const examPurpose = []

            updateItem.examPurpose.edges.forEach((updateItem) => {
                let itemIndex = examPurposeOptions.findIndex((item) => updateItem.node.id === item.node.id)
                if (itemIndex > -1) {
                    examPurpose.push(examPurposeOptions[itemIndex].node.id)
                }
            })

            setExamPurpose(examPurpose)
        }
    }, [updateItem, examPurposeOptions])

    // Function to update list on drop
    const handleDrop = (droppedItem, formik) => {
        let updatedList = [...formik.values.questions]

        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1)

        if (droppedItem.destination) {
            updatedList.splice(droppedItem.destination.index, 0, reorderedItem)
        }

        formik.setFieldValue("questions", updatedList)
    }

    function handleDismissItem(itemId, formik) {
        let selectedItems = formik.values.questions
        const index = selectedItems.findIndex((selectedItem) => selectedItem === itemId)

        selectedItems.splice(index, 1)

        formik.setFieldValue("questions", [...selectedItems])
    }

    const [displaySelectQuestion, setDisplaySelectQuestion] = React.useState(false)

    function handleAddSelectedQuestions(selectedQuestionsId, formik) {
        const questions = formik.values.questions
        formik.setFieldValue("questions", [...questions, ...selectedQuestionsId])
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={(data, { setErrors }) => {
                const register = updateItem ? updateQuestionnaire : createQuestionnaire
                register({
                    variables: {
                        id: updateItem?.id ?? null,
                        topic: data.topic.node.id,
                        description: data.description,
                        time: data.time,
                        examPurpose: data.examPurpose,
                        questionnaireQuestionnaireQuestion: data.questions.map((item, index) => ({
                            question: item,
                            order: index + 1,
                        })),
                    },
                })
                    .then((response) => {
                        const returned = updateItem
                            ? response?.data?.updateQuestionnaire
                            : response?.data?.createQuestionnaire
                        if (returned.questionnaire) {
                            successForm()
                        } else {
                            notifyError("Operation on questionnaire failed, try again")
                        }
                    })
                    .catch((error) => {
                        if (error.graphQLErrors.length) {
                            getFormErrors(error.graphQLErrors, notifyError, setErrors)
                        } else {
                            notifyError("Operation on questionnaire failed, try again")
                        }
                    })
            }}
        >
            {(formik) => (
                <Box sx={{ width: "100%" }}>
                    <Card variant={"outlined"} sx={quizLayoutStyles.componentCardContainer}>
                        <FormHeaderBar />
                        <CardContent>
                            <form onSubmit={formik.handleSubmit}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={7}>
                                        <TextField
                                            name={"topic"}
                                            label="Topic"
                                            variant={"filled"}
                                            size={"small"}
                                            select
                                            fullWidth
                                            {...formik.getFieldProps("topic")}
                                            error={formik.errors.topic && formik.touched.topic}
                                            helperText={
                                                formik.errors.topic && formik.touched.topic && formik.errors.topic
                                            }
                                        >
                                            {topicOptions?.map((option) => (
                                                <MenuItem key={option.node.id} value={option}>
                                                    {option.node.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={12} md={5}>
                                        <TextField
                                            name={"time"}
                                            label="Seconds to fulfill"
                                            variant={"filled"}
                                            size={"small"}
                                            fullWidth
                                            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                                            {...formik.getFieldProps("time")}
                                            error={formik.errors.time && formik.touched.time}
                                            helperText={formik.errors.time && formik.touched.time && formik.errors.time}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            name={"description"}
                                            label="Description"
                                            variant={"filled"}
                                            size={"small"}
                                            multiline
                                            rows={2}
                                            fullWidth
                                            {...formik.getFieldProps("description")}
                                            error={formik.errors.description && formik.touched.description}
                                            helperText={
                                                formik.errors.description &&
                                                formik.touched.description &&
                                                formik.errors.description
                                            }
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        For:{" "}
                                        {examPurposeOptions.map((option) => (
                                            <Chip
                                                variant="outlined"
                                                key={option.node.id}
                                                label={option.node.name}
                                                size="small"
                                                color={
                                                    formik.values.examPurpose.includes(option.node.id)
                                                        ? "primary"
                                                        : "default"
                                                }
                                                onClick={() => {
                                                    let examPurpose = [...formik.values.examPurpose]
                                                    const index = examPurpose.findIndex(
                                                        (item) => item === option.node.id
                                                    )
                                                    if (index > -1) {
                                                        examPurpose.splice(index, 1)
                                                    } else {
                                                        examPurpose.push(option.node.id)
                                                    }
                                                    formik.setFieldValue("examPurpose", examPurpose)
                                                }}
                                                sx={{ mx: 1 }}
                                            />
                                        ))}
                                    </Grid>

                                    <Grid item xs={12} container spacing={1} sx={{ p: 1 }}>
                                        <Grid item xs={12}>
                                            <Button
                                                disabled={!formik.values.topic}
                                                onClick={() => setDisplaySelectQuestion(true)}
                                            >
                                                Add questions
                                            </Button>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <QuestionnaireQuestionsRearrange
                                                selectedQuestionsId={formik.values.questions}
                                                possibleQuestions={questionOptions}
                                                handleDropItem={(droppedItem) => handleDrop(droppedItem, formik)}
                                                handleDismissItem={(itemId) => handleDismissItem(itemId, formik)}
                                            />
                                        </Grid>

                                        {displaySelectQuestion && (
                                            <QuestionnaireQuestionSelect
                                                open={displaySelectQuestion}
                                                handleClose={() => setDisplaySelectQuestion(false)}
                                                possibleQuestions={questionOptions.filter(
                                                    (item) =>
                                                        !formik.values.questions.includes(item.node.id) &&
                                                        formik.values.topic.node.id === item.node.topic.id
                                                )}
                                                handleAddQuestions={(selectedQuestionsId) =>
                                                    handleAddSelectedQuestions(selectedQuestionsId, formik)
                                                }
                                            />
                                        )}
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} container justifyContent={"flex-end"} p={1} m={1}>
                                    <Button
                                        color={"secondary"}
                                        variant={"contained"}
                                        size={"small"}
                                        sx={{ m: 1 }}
                                        onClick={() => navigate("/quiz/manage-questionnaire")}
                                    >
                                        Cancel
                                    </Button>

                                    <LoadingButton
                                        type={"submit"}
                                        color={"primary"}
                                        variant={"contained"}
                                        size={"small"}
                                        sx={{ m: 1 }}
                                        disabled={!formik.isValid}
                                        loading={loadingCreate || loadingUpdate}
                                    >
                                        {updateItem ? "Update" : "Create"}
                                    </LoadingButton>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                </Box>
            )}
        </Formik>
    )
}
