const tableStyles = {
    row: {
        backgroundColor: "white",
        transition: "background-color 500ms ease",
        "&:hover": {
            "& td div": {
                opacity: 1
            }
        },
        "&>td:last-child": {
            position: "relative"
        }
    },
    actionColumn: {
        opacity: 0,
        transition: "opacity 0.3s linear",

        backgroundImage: "linear-gradient(to left, rgb(245, 245, 245) 85%, rgba(0, 0, 0, 0))",
        height: "100%",
        display: "flex",
        alignItems: "center",
        paddingLeft: "25px",
        paddingRight: "20px",

        position: "absolute",
        zIndex: 1,
        right: 0,
        top: 0
    },
}

export default tableStyles