import { gql } from "@apollo/client"

//all topic
export const LOAD_ALL_GLOSSARIES = gql`
    query allGlossaries(
        $orderBy: [String]
        $first: Int
        $last: Int
        $beforeCursor: String
        $afterCursor: String
        $wordFilter: String
    ) {
        allGlossaries(
            orderBy: $orderBy
            first: $first
            last: $last
            before: $beforeCursor
            after: $afterCursor
            word_Icontains: $wordFilter
        ) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            totalCount
            edges {
                node {
                    id
                    word
                    meaning
                }
            }
        }
    }`

//create topic
export const CREATE_GLOSSARY = gql`
    mutation createGlossary($word: String!, $meaning: String!) {
        createGlossary(input: { word: $word, meaning: $meaning }) {
            glossary {
                id
                word
                meaning
            }            
        }
    }
`

//update topic
export const UPDATE_GLOSSARY = gql`
    mutation updateGlossary($id: ID!, $word: String!, $meaning: String!){
        updateGlossary(id: $id, input: {word: $word, meaning: $meaning}) {
            glossary {
                id
                word
                meaning
            }
        }
    }`

//delete topic
export const DELETE_GLOSSARY = gql`
    mutation deleteGlossary($id: ID!){
        deleteGlossary(id: $id) {
            found
            deletedId
        }
    }`