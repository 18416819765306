import { useDispatch } from "react-redux"
import { useMutation } from "@apollo/client"
import { CREATE_CLIENT, USER_DETAILS } from "../queries/auth/userQueries"
import { createClient as createClientAction } from "../actions/auth/loginActions"
import { Backdrop, Button, CircularProgress, Grid, Typography } from "@mui/material"
import { ErrorNotification } from "./Notifications/Notifications"
import { RocketLaunchOutlined } from "@mui/icons-material"

export default function CreateClient(props) {
    const dispatch = useDispatch()

    const { user } = props

    const notifyError = message => ErrorNotification(message)

    const [createClient, {loading}] = useMutation(
        CREATE_CLIENT,
        {
            refetchQueries: [{query: USER_DETAILS}]
        }
    )

    async function callCreateClient() {
        createClient({ variables: { user: user.id } })
            .then(response => {
                if(response?.data?.createClient?.client) {
                    dispatch(createClientAction(response.data.createClient.client))
                } else {
                    notifyError("Client creation failed, try again")
                }
            })
            .catch(() => notifyError("Client creation failed, try again"))
    }

    return (
        <Backdrop open>
            <Grid container alignItems={"center"}>
                {
                    loading
                        ? <CircularProgress size={36} sx={{color: "white"}}/>
                        : <Grid container spacing={3}>
                            <Grid container item xs={12} justifyContent={"center"}>
                                <Grid item xs={12} md={8} lg={6}>
                                    <Typography variant={"h5"} sx={{ color: "white" }} textAlign={"center"}>
                                        Welcome to Real State Quiz. Here you can test your knowledge. Users can find
                                        hundreds of questions from different topics. Choose one subscription plan and
                                        enjoy our services.
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item xs={12} justifyContent={"center"}>
                                <Button
                                    variant={"outlined"}
                                    sx={{color: "white", borderColor: "white"}}
                                    onClick={() => callCreateClient()}
                                    startIcon={<RocketLaunchOutlined/>}
                                >
                                    Start
                                </Button>
                            </Grid>
                        </Grid>
                }
            </Grid>
        </Backdrop>
    )
}