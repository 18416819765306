import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"

//router
import { BrowserRouter } from "react-router-dom"

//apollo
import { client } from "./config/apollo.conf"
import { ApolloProvider } from "@apollo/client"

//redux
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/lib/integration/react"
import { store, persistor } from "./config/redux.conf"

import "../src/assets/styles/global-styles.css"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
    <BrowserRouter>
        <ApolloProvider client={client}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <App />
                </PersistGate>
            </Provider>
        </ApolloProvider>
    </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
