import * as React from 'react';

//material ui
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Tooltip from "@mui/material/Tooltip"
import IconButton from "@mui/material/IconButton"
import {
    Card,
    CardContent,
    Grid,
    Button,
    Chip
} from "@mui/material"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import { Cancel, Done, MoreVertOutlined } from "@mui/icons-material"

//router
import { useNavigate } from "react-router-dom"

//apollo
import { useQuery } from "@apollo/client"
import { CLIENT_EXAM_STATS } from "../../queries/auth/userExamQueries"

//redux
import { useSelector } from "react-redux"
import { ErrorNotification } from "../../components/Notifications/Notifications"

//components
import { LoadingSkeleton, TableNoData } from "../../utils/tableUtils"

//styles
import tableStyles from "../../assets/styles/tableStyles"
import quizLayoutStyles from "../../assets/styles/quizLayoutStyles"

export default function DashboardTrainingExamsStats(props) {
    const navigate = useNavigate()
    const authUser = useSelector((state) => state.loginReducer)

    const notifyError = message => ErrorNotification(message)

    const {data, loading} = useQuery(
        CLIENT_EXAM_STATS,
        {
            variables: {id: authUser.client.id},
            fetchPolicy: "network-only",
            onError: () => {
                notifyError("Load exam stats failed, try again")
            }
        }
    )

    const TableHeaderBar = () => {
        return (
            <AppBar position="static" color={"transparent"}>
                <Toolbar>
                    <Box>
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{
                                mr: 2,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            Train Quiz stats
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
        );
    }

    function EnhancedTableHead(props) {
        const headCells = [
            {
                id: 'number',
                label: '#',
            },
            {
                id: 'questionnaire',
                label: 'Questionnaire',
            },
            {
                id: 'completed',
                label: 'Completed',
            },
        ]

        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={'left'}
                            padding={'normal'}
                        >
                            { headCell.label }
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    return (
        <Box sx={{ width: "100%" }}>
            <Card variant={"outlined"} sx={quizLayoutStyles.componentCardContainer}>
                <TableHeaderBar />
                <CardContent>
                    <Grid container spacing={1}>
                        {loading ? (
                            <LoadingSkeleton />
                        ) : !data?.client?.clientExam.edges?.length ? (
                            <TableNoData />
                        ) : (
                            <TableContainer sx={{ maxHeight: "calc(70vh)" }}>
                                <Table aria-labelledby="tableTitle" size={"small"}>
                                    <EnhancedTableHead />
                                    <TableBody>
                                        {data?.client?.clientExam?.edges
                                            ?.filter((item) => item.node.examPurpose.name.toUpperCase() === "TRAIN")
                                            .map((row, index) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        tabIndex={-1}
                                                        key={row.node.id}
                                                        sx={tableStyles.row}
                                                    >
                                                        <TableCell padding='none' align='center'>{index + 1}</TableCell>
                                                        <TableCell>{row.node.questionnaire.description}</TableCell>
                                                        <TableCell>
                                                            {!!row.node.finishTime ? (
                                                                <Chip
                                                                    size={"small"}
                                                                    variant={"outlined"}
                                                                    color={"success"}
                                                                    label={"done"}
                                                                    icon={<Done />}
                                                                />
                                                            ) : (
                                                                <Chip
                                                                    size={"small"}
                                                                    variant={"outlined"}
                                                                    color={"error"}
                                                                    label={"incomplete"}
                                                                    icon={<Cancel />}
                                                                />
                                                            )}
                                                            <Box sx={tableStyles.actionColumn}>
                                                                <Tooltip title={"See summary"}>
                                                                    <IconButton
                                                                        size={"small"}
                                                                        sx={{ m: "3px" }}
                                                                        onClick={() =>
                                                                            navigate(
                                                                                `/quiz/questionnaire/${row.node.questionnaire.id}/exam/${row.node.id}/summary`
                                                                            )
                                                                        }
                                                                    >
                                                                        <MoreVertOutlined fontSize={"small"} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Grid>

                    <Box sx={{ display: "flex", justifyContent: "right", p: 1, m: 1 }}>
                        <Button
                            type={"submit"}
                            color={"primary"}
                            variant={"contained"}
                            size={"small"}
                            sx={{ m: 1 }}
                            onClick={() => navigate("/quiz/dashboard")}
                        >
                            Return
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    )
}