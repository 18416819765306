import React from "react"

//material
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid
} from "@mui/material"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"

//router
import { useNavigate } from "react-router-dom"

export default function UsageTermsPage(props) {
    const navigate = useNavigate()

    const TableHeaderBar = () => {
        return (
            <AppBar position="static">
                <Toolbar disableGutters sx={{p: 1, display: "flex", alignItems: "center", justifyContent: 'space-between'}}>
                    <Typography
                        variant="h6"
                        noWrap
                        sx={{
                            mr: 2,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        Usage terms
                    </Typography>
                </Toolbar>
            </AppBar>
        )
    }

    return (
        <Box sx={{width: "100%", mt: 3, display: "flex", alignItems: "center", justifyContent: "center"}}>
            <Card variant={"elevation"} sx={{width: {xs: 1, sm: 1, md: "90%", lg: "80%", xl: "70%"}}}>
                <TableHeaderBar/>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{textAlign: "justify"}}>
                            <Typography variant={"body2"}>
                                Welcome to Real State Quiz. The Real State Quiz branded and related services are provided by
                                CeticSoft.com, LLC, through the Real State Quiz Website https://quizrealestateexam.com.
                                This Terms of Service Agreement (“Agreement”) is entered into by and between CeticSoft
                                and you, and is made effective as of the date of your use of this website or the date
                                of electronic acceptance. The following terms and conditions govern our relationship
                                with our clients and others who interact with our website.
                            </Typography>

                            <Typography variant={"body2"}>
                                The services offered by the software are only accessible on our website at
                                <a href={"quizrealestateexam.com"}> quizrealestateexam.com</a>
                            </Typography>
                        </Grid>

                        <Grid item xs={12} style={{textAlign: "justify"}}>
                            <Typography variant={"subtitle2"}>
                                Amendments to Terms of service
                            </Typography>
                            <Typography variant={"body2"}>
                                CeticSoft the right to change or update this Agreement without notice. In the
                                appropriate time, CeticSoft will notify users about the changes that could affect
                                their use of the Service.
                            </Typography>
                        </Grid>

                        <Grid item xs={12} style={{textAlign: "justify"}}>
                            <Typography variant={"subtitle2"}>
                                Registration and access settings
                            </Typography>
                            <Typography variant={"body2"}>
                                In order to create an account, you hereby affirm that you provide accurate information
                                about your identity. Used email to sign up in our system must be the same as the one 
                                used by tha PayPal account that user will use to pay its subscription. If those emails 
                                address do not match the system won't be capable of associate payments with system users. 
                            </Typography>
                            <li>
                                You cannot create an account, username or password that is the personal information
                                of another person, words that are the trademarks, the property of another party
                                (including ours).
                            </li>
                            <li>
                                Users are responsible for maintaining the security of their account login information
                                (username and password) as Real State Quiz cannot and will not be liable for any loss or
                                damage from failure to comply with this security obligation.
                            </li>
                        </Grid>

                        <Grid item xs={12} style={{textAlign: "justify"}}>
                            <Typography variant={"subtitle2"}>
                                Restrictions
                            </Typography>
                            <Typography variant={"body2"}>
                                When agreeing to these terms, CeticSoft guarantees you a limited, non-transferable,
                                non-exclusive license to use and access the Service as long as you do not perform the
                                following actions:
                            </Typography>
                            <li>
                                Exploit the terms of this licence (for instance to licence, sublicense, sell, resell,
                                rent, lease, transfer, assign, distribute) or make the Service available for access or
                                use by any person(s) other than the Users, save as for is expressly permitted by this
                                Agreement or authorised by CeticSoft.
                            </li>
                            <li>
                                Use the Service to process any data unlawfully or for any third party.
                            </li>
                            <li>
                                Attempt to determine the source code of the Software. For instance, you shall not modify,
                                adapt, decompile, decipher, reverse engineer or otherwise change the source code, which
                                makes up the Service.
                            </li>
                            <li>
                                Harm the software with viruses, malware or trojan horses, by uploading, storing or
                                transmitting any malicious code.
                            </li>
                            <li>
                                Use the Service in a manner that interferes or disrupts with the provision of the
                                Service by Real State Quiz to third parties.
                            </li>
                            <li>
                                Use the Service to display or show any material which is of a defamatory, offensive,
                                abusive or menacing character to any other person.
                            </li>
                            <li>
                                Use the Service for any transmission, display or publication of any material in breach
                                of the Data Protection Act 1998 (or any amending statute) dealing with data protection
                                or similar legislation in any other country of any material which is confidential or
                                is a trade secret.
                            </li>
                            <li>
                                You may not use your account for any illegal or unauthorised purpose. You must not,
                                during your use of this Service, violate any laws in your jurisdiction (including,
                                but not limited to copyright laws).
                            </li>
                            <li>
                                You agree to indemnify CeticSoft against all and any losses, costs and expenses
                                CeticSoft may incur as a result of any breach by you.
                            </li>
                            <li>
                                The above list is not intended to be complete. CeticSoft have the right to remove any
                                content and suspend or terminate the account of any user who in our sole judgment is in
                                breach of the Terms of Service.
                            </li>
                        </Grid>

                        <Grid item xs={12} style={{textAlign: "justify"}}>
                            <Typography variant={"subtitle2"}>
                                Cancellation and termination
                            </Typography>
                            <Typography variant={"body2"}>
                                Real State Quiz have the right to suspend or terminate your account and current or future
                                use of service due to any reason. Termination or suspension will result in deletion of
                                your account.
                            </Typography>
                        </Grid>

                        <Grid item xs={12} style={{textAlign: "justify"}}>
                            <Typography variant={"subtitle2"}>
                                Right to use the service and maintenance
                            </Typography>
                            <li>
                                Real State Quiz cannot guarantee that the Service will be continuously accessible as
                                the Service may be inaccessible from time to time due to:
                                <ol>
                                    <li>Planned downtime for updates and / or maintenance of the Service.</li>
                                    <li>Any circumstances that are beyond our control, such as technical failures.</li>
                                </ol>
                            </li>
                            <li>
                                In order to fulfill its commitments in the supervision and review of the Service,
                                Real State Quiz may at any time correct the Service and any documentation related to it for
                                any reason, including, among others: legal, technical or commercial considerations.
                            </li>
                        </Grid>

                        <Grid item xs={12} style={{textAlign: "justify"}}>
                            <Typography variant={"subtitle2"}>
                                Customer Support
                            </Typography>
                            <Typography variant={"body2"}>
                                Customer support is available on our website via email at ceticsoftdev@gmail.com
                            </Typography>
                        </Grid>

                        <Grid item xs={12} style={{textAlign: "justify"}}>
                            <Typography variant={"subtitle2"}>
                                Code ownership
                            </Typography>
                            <Typography variant={"body2"}>
                                Unauthorized distribution of the Software without earlier assent is entirely prohibited
                                and includes placing our Software on any physical or virtual servers or mediums without
                                special agreement or a written consent form CeticSoft.
                            </Typography>
                        </Grid>

                        <Grid item xs={12} style={{textAlign: "justify"}}>
                            <Typography variant={"subtitle2"}>
                                Data Loss and Backups
                            </Typography>
                            <li>
                                CeticSoft will make great effort to guarantee that your information is secure when
                                using Real State Quiz Service.
                            </li>
                            <li>
                                CeticSoft will not accept beneath any circumstances the obligation for any liability
                                for any loss of client information whether that be through general use, hacking or
                                server failure.
                            </li>
                            <li>
                                CeticSoft will operate one daily backup of the subscription account data and the
                                server itself.
                            </li>
                        </Grid>

                        <Grid item xs={12} style={{textAlign: "justify"}}>
                            <Typography variant={"subtitle2"}>
                                Warranty
                            </Typography>
                            <Typography variant={"body2"}>
                                Real State Quiz warrants that:
                            </Typography>
                            <li>
                                It makes great effort to secure the software and checks the Service for the most
                                commonly known infections.
                            </li>
                            <li>
                                The Service will be provided with all due care, aptitude and constancy and by means
                                of appropriately qualified and talented personnel.
                            </li>
                            <li>
                                The Service will work and function as described on the website.
                            </li>
                            <li>
                                Real State Quiz have the right to provide the Service and any accompanying materials as
                                mulled contemplated beneath this Agreement and does not and will not encroach the
                                Intellectual Property Rights or other rights of any third party.
                            </li>
                        </Grid>

                        <Grid item xs={12} style={{textAlign: "justify"}}>
                            <Typography variant={"subtitle2"}>
                                Confidentiality
                            </Typography>
                            <Typography variant={"body2"}>
                                CeticSoft will at all times be committed to guaranteeing the confidentiality of data.
                                Any data submitted by the client will only be used by Real State Quiz in agreement
                                with the instructions of the client or in accordance with the terms of this Agreement.
                            </Typography>
                        </Grid>

                        <Grid item xs={12} style={{textAlign: "justify"}}>
                            <Typography variant={"subtitle2"}>
                                Liability
                            </Typography>
                            <Typography variant={"body2"}>
                                In no event will we or our directors, employees, or agents be liable to you or any
                                third party for any direct, indirect, consequential, exemplary, incidental, special,
                                or punitive damages, including lost profit, lost revenue, loss of data, or other damages
                                arising from your use of the site, even if we have been advised of the possibility of
                                such damages.
                            </Typography>
                        </Grid>

                        <Grid item xs={12} style={{textAlign: "justify"}}>
                            <Typography variant={"subtitle2"}>
                                Liability
                            </Typography>
                            <Typography variant={"body2"}>
                                Uploading any restricted content as listed below could lead to immediate account termination.
                            </Typography>
                            <Typography variant={"body2"}>
                                You must not transfer, post, host or transmit the subsequent things to or from the
                                Service together with however not restricted to:
                            </Typography>
                            <li>Uninvited email, SMS’s, or “Spam” messages</li>
                            <li>Worms, viruses or code of a harmful nature</li>
                            <li>Questionable or outlawed material – together with copyrighted material</li>
                        </Grid>

                        <Grid item xs={12} style={{textAlign: "justify"}}>
                            <Typography variant={"subtitle2"}>
                                Indemnity
                            </Typography>
                            <Typography variant={"body2"}>
                                You agree to protect, defend, indemnify and hold harmless CeticSoft and its officers,
                                directors, employees, agents, and third party service providers from and against any
                                and all claims, demands, costs, expenses, losses, liabilities and damages of every kind
                                and nature (including, without limitation, reasonable attorneys’ fees) imposed upon or
                                incurred by CeticSoft directly or indirectly arising from your use of and access to this
                                Site or the Services found at this Site;  your violation of any provision of this
                                Agreement or the policies or agreements which are incorporated herein; and/or your
                                violation of any third-party right, including without limitation any intellectual
                                property or other proprietary right. The indemnification obligations under this section
                                shall survive any termination or expiration of this Agreement or your use of this Site
                                or the Services found at this Site.
                            </Typography>
                        </Grid>

                        <Grid item xs={12} style={{textAlign: "justify"}}>
                            <Typography variant={"subtitle2"}>
                                Compliance with local laws
                            </Typography>
                            <Typography variant={"body2"}>
                                CeticSoft makes no representation or warranty that the content available on this Site
                                or the Services found at this Site are appropriate in every country or jurisdiction,
                                and access to this Site or the Services found at this Site from countries or jurisdictions
                                where its content is illegal is prohibited.  Users who choose to access this Site or
                                the Services found at this Site are responsible for compliance with all local laws,
                                rules and regulations.
                            </Typography>
                        </Grid>

                        <Grid item xs={12} style={{textAlign: "justify"}}>
                            <Typography variant={"subtitle2"}>
                                General
                            </Typography>
                            <Typography variant={"body2"}>
                                Nothing in this Agreement is intended to confer on a person any right to enforce any
                                term of this Agreement which that person would not have had but for the Contract
                                (Rights of Third Parties) Act 1999.
                                All notices in reference to this Agreement must be in English, in writing, addressed
                                to the other party and sent to your address embarked on your Company profile or to
                                ceticsoftdev@gmail.com (as applicable) or such different address as either party has
                                notified the opposite in accordance with this clause. All notices shall be deemed to
                                have been given on receipt as verified by written or automated receipt or electronic
                                log (as applicable). All other notices must be in English, in writing, addressed to the
                                other party’s primary contact and sent to their then current postal address or email address.
                                All disputes between the parties arising out of or with reference to this Agreement or
                                the breach, termination or validity thence shall be referred by either party in writing,
                                first to every party’s representative. The representatives shall meet and arrange to
                                resolve the dispute within a period of thirty (30) operating days from the date of referral
                                of the dispute to them.
                                Each party acknowledges that in stepping into this Agreement it’s not relied on, and
                                shall have no right or remedy in respect of, any statement, falsity, misrepresentation,
                                illustration, representation or assurance (whether created negligently or innocently)
                                and whether or not created by either party, orally or in writing, before the execution
                                of this Agreement and not expressly stated during this Agreement or any form.
                                This Agreement means that all terms are agreed between the parties and supersedes and
                                extinguishes all previous agreements, misrepresentations, understandings and arrangements
                                between the parties whether written or oral, relating to its subject matter.
                                You are not entitled to transfer or assign this Agreement without Ceticsoft prior written
                                consent.
                            </Typography>
                        </Grid>

                        <Grid item xs={12} style={{ textAlign: "justify" }}>
                            <Typography variant={"subtitle2"}>
                                English language controls
                            </Typography>
                            <Typography variant={"body2"}>
                                This Agreement, along with all policies and the applicable product agreements identified
                                above and incorporated herein by reference (collectively, the “Agreement”), is executed
                                in the English language. To the extent any translation is provided to you, it is
                                provided
                                for convenience purposes only, and in the event of any conflict between the English and
                                translated version, where permitted by law, the English version will control and
                                prevail.
                                Where the translated version is required to be provided to you and is to be considered
                                binding by law (i) both language versions shall have equal validity, (ii) each party
                                acknowledges that it has reviewed both language versions and that they are substantially
                                the same in all material respects, and (iii) in the event of any discrepancy between
                                these two versions, the translated version may prevail, provided that the intent of the
                                Parties has been fully taken into consideration.
                            </Typography>
                        </Grid>

                        <Grid item xs={12} style={{ textAlign: "justify" }}>
                            <Typography variant={"subtitle2"}>
                                Privacy policy
                            </Typography>
                            <Typography variant={"body2"}>
                                We at Real State Quiz are committed to protecting your privacy. Our privacy policy
                                governs
                                the collection, storage, and use of the personal information provided by you. By
                                accessing
                                our website or using our platform you agree to the terms of this privacy policy.
                            </Typography>
                        </Grid>

                        <Grid item xs={12} style={{ textAlign: "justify" }}>
                            <Typography variant={"body2"}>
                                If you do not agree with the data practices described in this Privacy Policy, you should
                                not use our services. If you have any questions regarding Real State Quiz Privacy
                                Policy, feel free to contact us through email at ceticsoftdev@gmail.com.
                            </Typography>
                        </Grid>

                        <Grid item xs={12} style={{ textAlign: "justify" }}>
                            <Typography variant={"subtitle2"}>
                                Information we collect from you
                            </Typography>
                            <li><b>Your identity details: </b>first name, last name.</li>
                            <li><b>Profile Data: </b>email address, username and password.</li>
                            <li><b>Technical Data: </b>Internet protocol (IP) address used to connect your computer
                                to the Internet, browser type and version, time zone setting, operating system, and
                                platform.
                            </li>
                        </Grid>

                        <Grid item xs={12} style={{ textAlign: "justify" }}>
                            <Typography variant={"subtitle2"}>
                                How we collect information
                            </Typography>
                            <li>By using cookies.</li>
                        </Grid>

                        <Grid item xs={12} style={{ textAlign: "justify" }}>
                            <Typography variant={"subtitle2"}>
                                Cookie policy
                            </Typography>
                            <Typography variant={"body2"}>
                                A cookie is a small piece of text sent to your browser by a website you visit.
                                It helps the site remember information about your visit, which can make it easier to
                                visit the site again and make the site more useful to you. In addition it allows making
                                the ads you see more relevant to you, to count how many visitors we receive to a page
                                and to help you sign up for our services, to protect your data, and to remember your ad
                                settings.
                            </Typography>
                        </Grid>

                        <Grid item xs={12} style={{ textAlign: "justify" }}>
                            <Typography variant={"subtitle2"}>
                                How de we use collected information
                            </Typography>
                            <li>
                                To establish, maintain, administer and support your account. We never impersonate
                                your account for troubleshooting purposes without your consent. In unique cases, a
                                dedicated Real State Quiz support member could offer the user an option to help solve an
                                issue with their account via direct access to their account. This level of support can
                                only be perform by a dedicated support team and only with specific agreement of the
                                account holder.
                            </li>
                            <li>To communicate with you over a phone, email or live chat.</li>
                            <li>To aggregate statistics about the use of our services.</li>
                            <li>For taxes and legal reasons.</li>
                        </Grid>

                        <Grid item xs={12} style={{ textAlign: "justify" }}>
                            <Typography variant={"subtitle2"}>
                                How long do we store your information
                            </Typography>
                            <Typography variant={"body2"}>
                                Real State Quiz will store your personal information for as-long as you are our user,
                                if your account becomes in-active and does not hold a subscription we will
                                automatically delete it after 60 days.
                            </Typography>
                        </Grid>

                        <Grid item xs={12} style={{ textAlign: "justify" }}>
                            <Typography variant={"subtitle2"}>
                                Whom we share your information with
                            </Typography>
                            <Typography variant={"body2"}>
                                Real State Quiz does not share your personal information with anyone ever.
                            </Typography>
                        </Grid>

                        <Grid item xs={12} style={{ textAlign: "justify" }}>
                            <Typography variant={"subtitle2"}>
                                How we secure your information
                            </Typography>
                            <Typography variant={"body2"}>
                                We are committed to ensuring that your information is secure. To prevent unauthorised
                                access or disclosure, we have put in place suitable physical, electronic, and managerial
                                procedures to safeguard and secure the information we collect online. Also, we limit
                                access to your personal data to those employees, agents, contractors, and other third
                                parties who have a business need to know. They will only process your personal data on
                                our instructions, and they are subject to a duty of confidentiality.
                            </Typography>
                        </Grid>

                        <Grid item xs={12} style={{ textAlign: "justify" }}>
                            <Typography variant={"subtitle2"}>
                                Edit or change your information
                            </Typography>
                            <Typography variant={"body2"}>
                                You can access all of your information in Real State Quiz, change or edit your
                                information at any time.
                            </Typography>
                        </Grid>

                        <Grid item xs={12} style={{ textAlign: "justify" }}>
                            <Typography variant={"subtitle2"}>
                                Changes to our privacy policy
                            </Typography>
                            <Typography variant={"body2"}>
                                This policy may be updated. Please check back frequently to see any updates or changes
                                to our privacy policy.
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} style={{ textAlign: "justify" }}>
                        <Typography variant={"subtitle2"}>
                            Contact information
                        </Typography>
                        <Typography variant={"body2"}>
                            If you have any questions about this Agreement, please contact us by email or regular
                            mail at the following address: <br />

                            CeticSoft Legal Department <br />
                            14455 North Hayden Rd.<br />
                            Suite 219<br />
                            Scottsdale, AZ 85260<br />
                            legal@godaddy.com<br />
                        </Typography>
                    </Grid>

                    <Grid container item xs={12} justifyContent={"flex-end"}>
                        <Button
                            color={"primary"}
                            variant={"contained"}
                            size={"small"}
                            sx={{m: 1}}
                            onClick={() => navigate("/")}
                        >
                            Done
                        </Button>
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    )
}