import { gql } from "@apollo/client"

//all questionnaires
export const LOAD_ALL_QUESTIONNAIRES = gql`
    query allQuestionnairesFiltered(
        $orderBy: [String]
        $first: Int
        $last: Int
        $beforeCursor: String
        $afterCursor: String
        $descriptionFilter: String
    ) {
        allQuestionnaires(
            orderBy: $orderBy
            first: $first
            last: $last
            before: $beforeCursor
            after: $afterCursor
            description_Icontains: $descriptionFilter
        ) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            totalCount
            edges {
                node {
                    id
                    topic {
                        id
                        name
                        description
                    }
                    description
                    questionnaireQuestionnaireQuestion {
                        edges {
                            node {
                                id
                                question {
                                    id
                                    topic {
                                        id
                                        name
                                    }
                                    description
                                }
                            }
                        }
                    }
                    time
                    examPurpose {
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`

//all questionnaires to select one and start exam
export const LOAD_QUESTIONNAIRE_OPTIONS = gql`
    query allQuestionnaires {
        allQuestionnaires {
            edges {
                node {
                    id
                    topic {
                        id
                        name
                    }
                    description
                    questionnaireQuestionnaireQuestion {
                        edges {
                            node {
                                id
                            }
                        }
                    }
                    time
                    examPurpose {
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`

//questionnaire details to start exam
export const LOAD_QUESTIONNAIRE_DETAILS = gql`
    query questionnaire($id: ID!) {
        questionnaire(id: $id) {
            id
            topic {
                id
                name
                description
            }
            description
            time
            questionnaireQuestionnaireQuestion {
                edges {
                    node {
                        id
                        question {
                            id
                            topic {
                                id
                                name
                                description
                            }
                            description
                            explanation
                            questionPossibleAnswer {
                                edges {
                                    node {
                                        id
                                        description
                                        isCorrect
                                    }
                                }
                            }
                        }
                    }
                }
            }
            examPurpose {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
        }
    }
`

//all questions
export const ALL_QUESTIONS = gql`
    query allQuestions {
        allQuestions {
            edges {
                node {
                    id
                    description
                    topic {
                        id
                        name
                    }
                }
            }
        }
    }
`

//all exam purpose
export const ALL_EXAM_PURPUSES = gql`
    query allExamPurposes {
        allExamPurposes {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`

//exam details (questions from a questionnaire and information if it is already answered)
export const LOAD_EXAM_STATUS = gql`
    query examStatus($examId: String!) {
        examStatus(examId: $examId) {
            exam {
                id
                questionnaire {
                    id
                    description
                    topic {
                        id
                        name
                    }
                    time
                }
                examPurpose {
                    id
                    name
                }
            }
            questionStatusList {
                question {
                    id
                    description
                    topic {
                        id
                        name
                    }
                    questionPossibleAnswer {
                        edges {
                            node {
                                id
                                description
                            }
                        }
                    }
                    questionQuestionnaireQuestion {
                        edges {
                            node {
                                id
                                questionnaire {
                                    id
                                }
                            }
                        }
                    }
                }
                wasAnswered
            }
        }
    }
`

//create questionnaire with nested questions
export const CREATE_QUESTIONNAIRE = gql`
    mutation createQuestionnaire(
        $topic: ID!
        $description: String
        $time: Int!
        $questionnaireQuestionnaireQuestion: [CreateQuestionnaireInputExactQuestionnaireQuestionnairequestionnairequestion]
        $examPurpose: [ID]!
    ) {
        createQuestionnaire(
            input: {
                topic: $topic
                description: $description
                time: $time
                questionnaireQuestionnaireQuestion: $questionnaireQuestionnaireQuestion
                examPurpose: $examPurpose
            }
        ) {
            questionnaire {
                topic {
                    id
                    name
                }
                description
                time
                questionnaireQuestionnaireQuestion {
                    edges {
                        node {
                            id
                            question {
                                id
                                topic {
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
                examPurpose {
                    edges {
                        node {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`

//update questionnaire
export const UPDATE_QUESTIONNAIRE = gql`
    mutation updateQuestion(
        $id: ID!
        $topic: ID!
        $description: String
        $time: Int!
        $questionnaireQuestionnaireQuestion: [UpdateQuestionnaireInputExactQuestionnaireQuestionnairequestionnairequestion]
        $examPurpose: [ID]!
    ) {
        updateQuestionnaire(
            id: $id
            input: {
                topic: $topic
                description: $description
                time: $time
                questionnaireQuestionnaireQuestion: $questionnaireQuestionnaireQuestion
                examPurpose: $examPurpose
            }
        ) {
            questionnaire {
                topic {
                    id
                    name
                }
                description
                time
                questionnaireQuestionnaireQuestion {
                    edges {
                        node {
                            id
                            question {
                                id
                                topic {
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

//delete questionnaire
export const DELETE_QUESTIONNAIRE = gql`
    mutation deleteQuestionnaire($id: ID!) {
        deleteQuestionnaire(id: $id) {
            found
            deletedId
        }
    }
`
