import * as React from 'react';

//material
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {
    Card,
    CardContent,
    Grid,
    Button
} from "@mui/material"
import {
    CancelOutlined,
    CheckCircleOutlined,
    DescriptionOutlined,
    QuestionAnswerOutlined,
    TimerOutlined,
    TopicOutlined
} from "@mui/icons-material"

//router
import { useNavigate, useParams } from "react-router-dom"

//apollo
import { useQuery } from "@apollo/client"
import { EXAM_SUMMARY } from "../../queries/auth/userExamQueries"

//components
import { ErrorNotification } from "../../components/Notifications/Notifications"
import { LoadingSkeleton } from "../../utils/tableUtils"

//dates
import { intervalToDuration, formatDuration } from "date-fns"

//styles
import selectQuestionnaireStyles from "../../assets/styles/selectQuestionnaireStyles"
import quizLayoutStyles from "../../assets/styles/quizLayoutStyles"

export default function UserExamSummary(props) {
    const navigate = useNavigate()

    const { questionnaireId, examId } = useParams()

    const notifyError = message => ErrorNotification(message)

    const { data, loading } = useQuery(
        EXAM_SUMMARY,
        {
            variables: {
                examId: examId
            },
            fetchPolicy: "network-only",
            onError: () => {
                notifyError("Quiz summary load failed, try again")
            }
        }
    )

    const TableHeaderBar = () => {
        return (
            <AppBar position="static" color={"transparent"}>
                <Toolbar>
                    <Box>
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{
                                mr: 2,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            Quiz summary
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
        );
    }

    return (
        <Box sx={{ width: "100%" }}>
            <Card variant={"outlined"} sx={quizLayoutStyles.componentCardContainer}>
                <TableHeaderBar />
                <CardContent>
                    {loading ? (
                        <LoadingSkeleton />
                    ) : (
                        <>
                            <Grid container spacing={1}>
                                <Grid container item xs={12} spacing={1}>
                                    <Grid container item xs={12} spacing={1}>
                                        <Grid item sx={selectQuestionnaireStyles.leftFlex}>
                                            Quiz:
                                        </Grid>
                                        <Grid item sx={selectQuestionnaireStyles.rightFlex}>
                                            <DescriptionOutlined sx={{ mr: 1, color: "primary.main" }} />
                                            {data?.examSummary?.exam?.questionnaire?.description ?? "-"}
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} spacing={1}>
                                        <Grid item sx={selectQuestionnaireStyles.leftFlex}>
                                            Topic:
                                        </Grid>
                                        <Grid item sx={selectQuestionnaireStyles.rightFlex}>
                                            <TopicOutlined sx={{ mr: 1, color: "primary.main" }} />
                                            {data?.examSummary?.exam?.questionnaire?.topic?.name ?? "-"}
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} spacing={1}>
                                        <Grid item sx={selectQuestionnaireStyles.leftFlex}>
                                            Answers:
                                        </Grid>
                                        <Grid item sx={selectQuestionnaireStyles.rightFlex}>
                                            <QuestionAnswerOutlined sx={{ mr: 1, color: "primary.main" }} />
                                            {data?.examSummary?.answersTotal}
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} spacing={1}>
                                        <Grid item sx={selectQuestionnaireStyles.leftFlex}>
                                            Correct:
                                        </Grid>
                                        <Grid item sx={selectQuestionnaireStyles.rightFlex}>
                                            <CheckCircleOutlined color={"success"} sx={{ mr: 1 }} />
                                            {data?.examSummary?.questionsCorrect !== undefined
                                                ? `${data.examSummary.questionsCorrect} (${Math.trunc(
                                                      (data.examSummary.questionsCorrect /
                                                          data.examSummary.answersTotal) *
                                                          100
                                                  )}%)`
                                                : "-"}
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} spacing={1}>
                                        <Grid item sx={selectQuestionnaireStyles.leftFlex}>
                                            Wrong:
                                        </Grid>
                                        <Grid item sx={selectQuestionnaireStyles.rightFlex}>
                                            <CancelOutlined color={"error"} sx={{ mr: 1 }} />
                                            {data?.examSummary?.questionsInCorrect
                                                ? `${data.examSummary.questionsInCorrect} (${Math.trunc(
                                                      (data.examSummary.questionsInCorrect /
                                                          data.examSummary.answersTotal) *
                                                          100
                                                  )}%)`
                                                : "-"}
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} spacing={1}>
                                        <Grid item sx={selectQuestionnaireStyles.leftFlex}>
                                            Quiz time:
                                        </Grid>
                                        <Grid item sx={selectQuestionnaireStyles.rightFlex}>
                                            <TimerOutlined sx={{ mr: 1, color: "primary.main" }} />
                                            {data?.examSummary?.exam?.questionnaire.time
                                                ? formatDuration(
                                                      intervalToDuration({
                                                          start: 0,
                                                          end: data.examSummary.exam.questionnaire.time * 1000,
                                                      })
                                                  )
                                                : "-"}
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} spacing={1}>
                                        <Grid item sx={selectQuestionnaireStyles.leftFlex}>
                                            Finished in:
                                        </Grid>
                                        <Grid item sx={selectQuestionnaireStyles.rightFlex}>
                                            <TimerOutlined sx={{ mr: 1, color: "primary.main" }} />
                                            {data?.examSummary?.exam?.finishTime
                                                ? formatDuration(
                                                      intervalToDuration({
                                                          start: 0,
                                                          end: data.examSummary.exam.finishTime * 1000,
                                                      })
                                                  )
                                                : "-"}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Box sx={{ display: "flex", justifyContent: "right", p: 1, m: 1 }}>
                                <Button
                                    variant={"contained"}
                                    size={"small"}
                                    sx={{ m: 1 }}
                                    onClick={() =>
                                        navigate(
                                            `/quiz/questionnaire/${questionnaireId}/exam/${examId}/answers-details`
                                        )
                                    }
                                >
                                    Details
                                </Button>

                                <Button
                                    type={"submit"}
                                    color={"primary"}
                                    variant={"contained"}
                                    size={"small"}
                                    sx={{ m: 1 }}
                                    onClick={() => navigate("/quiz/dashboard")}
                                >
                                    Done
                                </Button>
                            </Box>
                        </>
                    )}
                </CardContent>
            </Card>
        </Box>
    )
}