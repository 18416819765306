export const singIn = (payload) => {
    return {
        type: "SING_IN",
        payload,
    }
}

export const singOut = () => {
    return {
        type: "SING_OUT",
    }
}

export const createClient = (payload) => {
    return {
        type: "CREATE_CLIENT",
        payload,
    }
}
