import { gql } from "@apollo/client"

//all topic
export const LOAD_ALL_TOPICS = gql`
    query allTopics(
        $orderBy: [String]
        $first: Int
        $last: Int
        $beforeCursor: String
        $afterCursor: String
        $nameFilter: String
    ) {
        allTopics(
            orderBy: $orderBy
            first: $first
            last: $last
            before: $beforeCursor
            after: $afterCursor
            name_Icontains: $nameFilter
        ) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            totalCount
            edges {
                node {
                    id
                    name
                    description
                }
            }
        }
    }`

//create topic
export const CREATE_TOPIC = gql`
    mutation createTopic($name: String!, $description: String) {
        createTopic(input: { name: $name, description: $description }) {
            topic {
                id
                name
                description
            }            
        }
    }
`

//update topic
export const UPDATE_TOPIC = gql`
    mutation updateTopic($id: ID!, $name: String!, $description: String){
        updateTopic(id: $id, input: {name: $name, description: $description}) {
            topic {
                id
                name
                description
            }
        }
    }`

//delete topic
export const DELETE_TOPIC = gql`
    mutation deleteTopic($id: ID!){
        deleteTopic(id: $id) {
            found
            deletedId
        }
    }`