import { gql } from "@apollo/client"

//register payment
export const I_PAID = gql`
    query iPaid {
        iPaid {
            isActive
            type
            subscriptionActiveUntil
        }
    }
`

//all payments
export const LOAD_ALL_PAYMENTS = gql`
    query allPayments($clientId: ID!) {
        allPayments(
            clientId: $clientId
            orderBy: "-transaction_date"
        ) {
            edges {
                node {
                    id
                    value
                    currencyCode
                    transactionDate
                }
            }
        }
    }
`
