import * as React from "react"

//material
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { Button, Card, CardContent, TextField } from "@mui/material"
import { LoadingButton } from "@mui/lab"

//formik
import { Formik } from "formik"
import * as Yup from "yup"

//router
import {useNavigate, useLocation} from "react-router-dom"

//apollo
import { useMutation } from "@apollo/client"
import { CREATE_TOPIC, LOAD_ALL_TOPICS, UPDATE_TOPIC } from "../../../queries/admin/topicQueries"

//components
import { ErrorNotification, SuccessNotification } from "../../../components/Notifications/Notifications"

//utils
import { getFormErrors } from "../../../utils/errorsUtils"

//styles
import quizLayoutStyles from "../../../assets/styles/quizLayoutStyles"

export default function AdminTopicForm() {
    const navigate = useNavigate()
    const location = useLocation()

    const updateItem = location.state?.updateItem

    const notifySuccess = message => SuccessNotification(message)
    const notifyError = message => ErrorNotification(message)

    const [createTopic, {loading: loadingCreate}] = useMutation(CREATE_TOPIC, {
        refetchQueries: [ LOAD_ALL_TOPICS ]
    })
    const [updateTopic, {loading: loadingUpdate}] = useMutation(UPDATE_TOPIC, {
        refetchQueries: [ LOAD_ALL_TOPICS ]
    })

    const successForm = () => {
        updateItem ? notifySuccess("Topic updated") : notifySuccess("Topic added")
        navigate("/quiz/manage-topics")
    }

    const FormHeaderBar = () => {
        return (
            <AppBar position="static" color={"transparent"}>
                <Toolbar disableGutters sx={quizLayoutStyles.componentHeaderToolbar}>
                    <Box>
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{
                                mr: 2,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            {
                                updateItem ? "Edit topic" : "Create topic"
                            }
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
        );
    };

    //formik
    const initialValues = {
        name: updateItem ? updateItem.name : "",
        description: updateItem ? updateItem.description : "",
    }

    const validationSchema = Yup.object({
        name: Yup.string().required('field required'),
        description: Yup.string().required('field required'),
    })

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={(data, { setErrors }) => {
                const register = updateItem ? updateTopic : createTopic
                register({
                    variables: {
                        id: updateItem?.id ?? null,
                        name: data.name,
                        description: data.description
                    }
                })
                    .then((response) => {
                        const returned = updateItem ? response?.data?.updateTopic : response?.data?.createTopic
                        if (returned.topic) {
                            successForm()
                        } else {
                            notifyError("Operation on topic failed, try again")
                        }
                    })
                    .catch(error => {
                        if (error.graphQLErrors.length) {
                            getFormErrors(error.graphQLErrors, notifyError, setErrors)
                        } else {
                            notifyError("Operation on topic failed, try again")
                        }
                    })
            }}
        >
            {
                formik => (
                    <Box sx={{width: "100%"}}>
                        <Card variant={"outlined"} sx={quizLayoutStyles.componentCardContainer}>
                            <FormHeaderBar/>
                            <CardContent sx={{ maxHeight: "calc(45vh)", overflowY: "auto" }}>
                                <form onSubmit={formik.handleSubmit}>
                                    <Box sx={{display: "flex", flexWrap: 'wrap', width: "100%"}}>
                                        <TextField
                                            name={"name"}
                                            label="Topic"
                                            variant={"filled"}
                                            size={"small"}
                                            fullWidth
                                            sx={{ m: 1 }}
                                            {...formik.getFieldProps("name")}
                                            error={formik.errors.name && formik.touched.name}
                                            helperText={(formik.errors.name && formik.touched.name) && formik.errors.name}
                                        />

                                        <TextField
                                            name={"description"}
                                            label="Description"
                                            variant={"filled"}
                                            size={"small"}
                                            fullWidth
                                            sx={{ m: 1 }}
                                            {...formik.getFieldProps("description")}
                                            error={formik.errors.description && formik.touched.description}
                                            helperText={(formik.errors.description && formik.touched.description) && formik.errors.description}
                                        />
                                    </Box>

                                    <Box sx={{display: "flex", justifyContent: "right", p: 1, m: 1}}>
                                        <Button
                                            color={"secondary"}
                                            variant={"contained"}
                                            size={"small"}
                                            sx={{m: 1}}
                                            onClick={() => navigate("/quiz/manage-topics")}
                                        >
                                            Cancel
                                        </Button>

                                        <LoadingButton
                                            type={"submit"}
                                            color={"primary"}
                                            variant={"contained"}
                                            size={"small"}
                                            sx={{m: 1}}
                                            disabled={!formik.isValid}
                                            loading={loadingCreate || loadingUpdate}
                                        >
                                            {updateItem ? "Update" : "Create"}
                                        </LoadingButton>
                                    </Box>
                                </form>
                            </CardContent>
                        </Card>
                    </Box>
                )
            }
        </Formik>
    )
}