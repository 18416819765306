import { gql } from "@apollo/client"

//user login with username and password
export const USER_DETAILS = gql`
    query me2 {
        me2 {
            id
            username
            firstName
            lastName
            email
            dateJoined
            lastLogin
            isStaff
            isSuperuser
            client {
                id
                nextPayment
                paymentType
            }
        }
    }
`

//new user registration
export const USER_REGISTER = gql`
    mutation register($email: String!, $username: String!, $password1: String!, $password2: String!) {
        register(input: { email: $email, username: $username, password1: $password1, password2: $password2 }) {
            success
            errors
        }
    }
`

//new user account verification
export const VERIFY_ACCOUNT = gql`
    query verifyAccount($token: String!) {
        verifyAccount(token: $token)
    }
`

//create client
export const CREATE_CLIENT = gql`
    mutation createClient($user: ID!) {
        createClient(input: { user: $user phone: "1234567" }) {
            client {
                id
            }
        }
    }
`

//authenticated user password change
export const PASSWORD_CHANGE = gql`
    mutation passwordChange($oldPassword: String!, $newPassword1: String!, $newPassword2: String!) {
        passwordChange(input: { oldPassword: $oldPassword, newPassword1: $newPassword1, newPassword2: $newPassword2 }) {
            success
            errors
            token
        }
    }
`

//authenticate user with social auth
export const LOGIN_SOCIAL = gql`
    mutation social($accessToken: String!, $provider: String!) {
        social(accessToken: $accessToken, provider: $provider) {
            social {
                token
                user {
                    id
                    username
                    isSuperuser
                    isStaff
                    groups {
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`

//send password reset email
export const SEND_PASSWORD_RESET_EMAIL = gql`
    mutation sendPasswordResetEmail($email: String!) {
        sendPasswordResetEmail(input: { email: $email }) {
            success
            errors
        }
    }
`

//reset password
export const PASSWORD_RESET = gql`
    mutation passwordReset($token: String!, $password1: String!, $password2: String!) {
        passwordReset(input: { token: $token, newPassword1: $password1, newPassword2: $password2 }) {
            success
            errors
        }
    }
`