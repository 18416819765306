const userProfileStyle = {
    leftFlex: {
        display: "flex",
        fontWeight: "600",
        justifyContent: "flex-end",
        width: {xs: 2/6, sm: 2/6, md: 2/6, lg: 2/6, xl: 2/6}
    },

    rightFlex: {
        display: "flex",
        width: {xs: 4/6, sm: 4/6, md: 4/6, lg: 4/6, xl: 4/6}
    }
}

export default userProfileStyle