import * as React from 'react';

//material
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {
    Button,
    Card,
    CardContent,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    TableSortLabel,
    Grid
} from "@mui/material"
import {
    AddOutlined,
    CancelOutlined,
    DeleteOutlined,
    EditOutlined,
    ExpandLessOutlined,
    ExpandMoreOutlined,
    SearchOffOutlined,
    SearchOutlined,
} from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"

//router
import { useNavigate,  } from "react-router-dom"

//apollo
import { useQuery } from "@apollo/client"
import { Mutation } from "@apollo/client/react/components"
import { DELETE_GLOSSARY, LOAD_ALL_GLOSSARIES } from "../../../queries/admin/glossaryQueries"

//styles
import tableStyles from "../../../assets/styles/tableStyles"

//components
import { ErrorNotification, SuccessNotification } from "../../../components/Notifications/Notifications"

//utils
import { ConfirmTransition, LoadingSkeleton, TableNoData } from "../../../utils/tableUtils"

//styles
import quizLayoutStyles from "../../../assets/styles/quizLayoutStyles"
import { visuallyHidden } from "@mui/utils"
import TablePagination from "@mui/material/TablePagination"
import TableFooter from "@mui/material/TableFooter"

export default function AdminGlossary() {
    const navigate = useNavigate()

    const notifySuccess = message => SuccessNotification(message)
    const notifyError = message => ErrorNotification(message)

    //pagination
    const [order, setOrder] = React.useState('asc')
    const [orderBy, setOrderBy] = React.useState('word')
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(5)

    //save server response data (page info)
    const [totalItemsPagination, setTotalItemsPagination] = React.useState(-1)
    const [startCursorPagination, setStartCursorPagination] = React.useState(null)
    const [endCursorPagination, setEndCursorPagination] = React.useState(null)

    const [queryVariables, setQueryVariables] = React.useState({
        orderBy: order === "asc" ? [orderBy] : [`-${orderBy}`],
        wordFilter: null,
        first: rowsPerPage,
        last: null,
        beforeCursor: null,
        afterCursor: null
    })

    const setPaginationResponse = (data) => [
        setTotalItemsPagination(data.allGlossaries.totalCount),
        setStartCursorPagination(data.allGlossaries.pageInfo.startCursor),
        setEndCursorPagination(data.allGlossaries.pageInfo.endCursor)
    ]

    const handleChangePage = (event, newPage) => {
        if (newPage > page) {
            setQueryVariables({
                ...queryVariables,

                first: rowsPerPage,
                last: null,
                beforeCursor: null,
                afterCursor: endCursorPagination
            })
        } else {
            setQueryVariables({
                ...queryVariables,

                first: null,
                last: rowsPerPage,
                beforeCursor: startCursorPagination,
                afterCursor: null
            })
        }
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)

        setQueryVariables({
            ...queryVariables,

            first: parseInt(event.target.value, 10),
            last: null,
            beforeCursor: null,
            afterCursor: null
        })
    }

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
        setPage(0)

        setQueryVariables({
            ...queryVariables,

            orderBy: order === "asc" ? [property] : [`-${property}`],
            first: rowsPerPage,
            last: null,
            beforeCursor: null,
            afterCursor: null
        })
    }

    const handleSearchInputChange = (descriptionValue) => {
        setPage(0)

        setQueryVariables({
            ...queryVariables,

            wordFilter: descriptionValue,

            first: rowsPerPage,
            last: null,
            beforeCursor: null,
            afterCursor: null
        })
    }

    //display search field
    const [showSearchInput, setShowSearchInput] = React.useState(false)
    const toggleSearchInput = () => {
        setShowSearchInput(!showSearchInput)
    }

    //search field
    const [searchValue, setSearchValue] = React.useState("")

    const handleSearchValueChange = (e) => {
        setSearchValue(e.target.value)
    }

    const { data, loading, refetch } = useQuery(LOAD_ALL_GLOSSARIES, {
        variables: queryVariables,
        onCompleted: data1 => {
            setPaginationResponse(data1)
        },
        fetchPolicy: "network-only"
    })

    const TableHeaderBar = (props) => {
        const {
            handleSearchInputChange,
            searchValue,
            handleSearchValueChange,
            showSearchInput,
            toggleSearchInput,
        } = props

        const handlePressEnter = (e) => {
            if (e.keyCode === 13) {
                applySearch()
            }
        }

        const applySearch = () => {
            handleSearchInputChange(searchValue)
            toggleSearchInput()
        }

        const cancelFilters = () => {
            setSearchValue("")
            handleSearchInputChange("")
            toggleSearchInput()
        }

        return (
            <AppBar position="static" color={"transparent"}>
                <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
                    {showSearchInput ? (
                        <TextField
                            placeholder={"Search by description..."}
                            autoFocus
                            size={"small"}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <Tooltip title={"Cancel search"}>
                                        <IconButton size={"small"} onClick={cancelFilters}>
                                            <CancelOutlined />
                                        </IconButton>
                                    </Tooltip>
                                ),
                                startAdornment: (
                                    <Tooltip title={"Search by description"}>
                                        <IconButton size={"small"} onClick={applySearch}>
                                            <SearchOutlined />
                                        </IconButton>
                                    </Tooltip>
                                ),
                            }}
                            value={searchValue}
                            onChange={handleSearchValueChange}
                            onKeyDown={handlePressEnter}
                        />
                    ) : (
                        <>
                            <Box>
                                <Typography
                                    variant="h6"
                                    noWrap
                                    sx={{
                                        mr: 2,
                                        fontFamily: "monospace",
                                        fontWeight: 700,
                                        letterSpacing: ".3rem",
                                        color: "inherit",
                                        textDecoration: "none",
                                    }}
                                >
                                    Glossary
                                </Typography>
                            </Box>

                            <Box sx={{ display: "flex" }}>
                                <Tooltip title="Filter data">
                                    <IconButton
                                        aria-label="filter data"
                                        color={searchValue ? "secondary" : "default"}
                                        onClick={toggleSearchInput}
                                    >
                                        {searchValue ? <SearchOutlined /> : <SearchOffOutlined />}
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Add word definition">
                                    <IconButton onClick={() => navigate("/quiz/manage-glossary/form")}>
                                        <AddOutlined />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </>
                    )}
                </Toolbar>
            </AppBar>
        )
    }

    function EnhancedTableHead(props) {
        const { order, orderBy, onRequestSort } = props

        const headCells = [
            {
                id: 'word',
                label: 'Word',
                sort: true,
            },
            {
                id: 'meaning',
                label: 'Definition',
                sort: true,
            },
        ]

        const createSortHandler = (property) => () => {
            onRequestSort(property);
        }

        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={'left'}
                            padding={'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            {
                                headCell.sort
                                    ? <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : "asc"}
                                        onClick={createSortHandler(headCell.id)}
                                    >
                                        {headCell.label}
                                        {orderBy === headCell.id ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {order === "desc" ? "sorted descending" : "sorted ascending"}
                                            </Box>
                                        ) : null}
                                    </TableSortLabel>
                                    : headCell.label
                            }
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    const updateItem = (e, item) => {
        e.stopPropagation()
        navigate("/quiz/manage-glossary/form", {state: {updateItem: item}})
    }

    const [openDialog, setOpenDialog] = React.useState(false)
    const [deleteItemIndex, setDeleteItemIndex] = React.useState(null)

    const deleteItem = (e, item) => {
        e.stopPropagation()
        setDeleteItemIndex(item.node.id)
        setOpenDialog(true)
    }

    function ConfirmDialog(props) {
        const {register, refetch, loading, openDialog, setOpenDialog} = props

        const handleClose = () => {
            setOpenDialog(false)
        }

        const deleteItem = () => (
            register({
                variables: {
                    id: deleteItemIndex
                }
            }).then(
                (response) => {
                    if (response.data.deleteGlossary.found) {
                        notifySuccess("Word definition deleted")
                        refetch()
                    }
                    handleClose()
                },
                () => {
                    notifyError("Delete word definition failed, try again")
                    handleClose()
                }
            )
        )


        return (
            <div>
                <Dialog
                    open={openDialog}
                    TransitionComponent={ConfirmTransition}
                    keepMounted
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle>
                        Delete question
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography gutterBottom>
                            Do you confirm to delete the word definition?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="secondary" disabled={loading}>
                            Cancel
                        </Button>
                        <LoadingButton
                            color={"primary"}
                            variant={"contained"}
                            loading={loading}
                            onClick={deleteItem}
                        >
                            Confirm
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }

    //expand description
    const [expandIndex, setExpandIndex] = React.useState(null)
    function handleExpand(rowIndex) {
        if (rowIndex === expandIndex)
            return setExpandIndex(null)

        return setExpandIndex(rowIndex)
    }

    return (
        <Mutation
            mutation={DELETE_GLOSSARY}
        >
            {
                (register, {loading: loadingDelete}) => (
                    <Box sx={{width: "100%"}}>
                        <ConfirmDialog
                            openDialog={openDialog}
                            setOpenDialog={setOpenDialog}
                            register={register}
                            refetch={refetch}
                            loading={loadingDelete}
                        />

                        <Card variant={"outlined"} sx={quizLayoutStyles.componentCardContainer}>
                            <TableHeaderBar
                                handleSearchInputChange={handleSearchInputChange}
                                searchValue={searchValue}
                                handleSearchValueChange={handleSearchValueChange}
                                showSearchInput={showSearchInput}
                                toggleSearchInput={toggleSearchInput}
                            />
                            <CardContent>
                                {
                                    loading
                                        ? <LoadingSkeleton/>
                                        : !data?.allGlossaries?.edges?.length
                                            ? <TableNoData/>
                                            : <TableContainer sx={{ maxHeight: "calc(70vh)" }}>
                                                <Table
                                                    aria-labelledby="tableTitle"
                                                    size={'small'}
                                                >
                                                    <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort}/>
                                                    <TableBody>
                                                        {data?.allGlossaries?.edges?.map((row, rowIndex) => {
                                                            return (
                                                                <React.Fragment key={row.node.id}>
                                                                    <TableRow
                                                                        hover
                                                                        tabIndex={-1}
                                                                        sx={tableStyles.row}
                                                                        onClick={() => handleExpand(rowIndex)}
                                                                    >
                                                                        <TableCell>{row.node.word}</TableCell>
                                                                        <TableCell>
                                                                            {
                                                                                row.node.meaning
                                                                                    ? row.node.meaning.length > 70
                                                                                        ? row.node.meaning.substring(0, 70) + "..."
                                                                                        : row.node.meaning
                                                                                    : "-"
                                                                            }
                                                                            <Box sx={tableStyles.actionColumn}>
                                                                                <Tooltip title={"Details"}>
                                                                                    <IconButton
                                                                                        size={"small"}
                                                                                        sx={{m: "3px"}}
                                                                                        onClick={() => handleExpand(rowIndex)}
                                                                                    >
                                                                                        {
                                                                                            expandIndex === rowIndex
                                                                                                ? <ExpandLessOutlined/>
                                                                                                : <ExpandMoreOutlined/>
                                                                                        }
                                                                                    </IconButton>
                                                                                </Tooltip>

                                                                                <Tooltip title={"Edit"}>
                                                                                    <IconButton
                                                                                        color={"warning"}
                                                                                        size={"small"}
                                                                                        sx={{m: "3px"}}
                                                                                        onClick={(e) => updateItem(e, row.node)}
                                                                                    >
                                                                                        <EditOutlined fontSize={"small"}/>
                                                                                    </IconButton>
                                                                                </Tooltip>

                                                                                <Tooltip title={"Delete"}>
                                                                                    <IconButton
                                                                                        color={"error"}
                                                                                        size={"small"}
                                                                                        sx={{m: "3px"}}
                                                                                        onClick={(e) => deleteItem(e, row)}
                                                                                    >
                                                                                        <DeleteOutlined fontSize={"small"}/>
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </Box>
                                                                        </TableCell>
                                                                    </TableRow>

                                                                    <TableRow>
                                                                        <TableCell
                                                                            style={{ padding: 0 }}
                                                                            colSpan={2}>
                                                                            <Collapse in={expandIndex === rowIndex}
                                                                                      timeout="auto" unmountOnExit>
                                                                                <Grid container spacing={1} sx={{padding: "8px 26px"}}>
                                                                                    <Grid item xs={12}>
                                                                                        <Typography variant="subtitle1"
                                                                                                    gutterBottom
                                                                                                    component="div">
                                                                                            Details
                                                                                        </Typography>
                                                                                    </Grid>

                                                                                    <Grid container item spacing={1} xs={12}>
                                                                                        <Grid item xs={"auto"} sx={{fontWeight: 600}}>Word:</Grid>
                                                                                        <Grid item xs={true}>{row.node.word}</Grid>
                                                                                    </Grid>

                                                                                    <Grid container item spacing={1} xs={12}>
                                                                                        <Grid item xs={"auto"} sx={{fontWeight: 600}}>Meaning:</Grid>
                                                                                        <Grid item xs={true}>{row.node.meaning}</Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Collapse>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </React.Fragment>
                                                            )
                                                        })}
                                                    </TableBody>
                                                    <TableFooter>
                                                        <TableRow>
                                                            <TablePagination
                                                                rowsPerPageOptions={[5, 10, 25]}
                                                                colSpan={2}
                                                                SelectProps={{
                                                                    inputProps: {
                                                                        'aria-label': 'rows per page',
                                                                    },
                                                                }}
                                                                count={totalItemsPagination}
                                                                rowsPerPage={rowsPerPage}
                                                                page={page}
                                                                onPageChange={handleChangePage}
                                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                            />
                                                        </TableRow>
                                                    </TableFooter>
                                                </Table>
                                            </TableContainer>
                                }
                            </CardContent>
                        </Card>
                    </Box>
                )
            }
        </Mutation>
    )
}