const selectQuestionnaireStyles = {
    card: {
        position: "relative",
        border: "1px solid #3498db",
        borderRadius: "8px",
        width: "300px",
        maxWidth: "300px",
        p: 1,
        background: "rgba(112,226,218,0.7)",
        cursor: "pointer",
        transition: "background 0.48s",
        boxShadow: "none",
        "&:hover": {
            boxShadow: `0 4px 6px 0 rgba(0,0,0,0.5)`,
            background: "rgba(53,197,60,0.7)",
        },
    },

    leftFlex: {
        display: "flex",
        fontWeight: "600",
        justifyContent: "flex-end",
        width: {xs: 1/2, sm: 4/12, md: 3/12, lg: 3/12, xl: 3/12}
    },

    rightFlex: {
        display: "flex",
        width: {xs: 1/2, sm: 8/12, md: 9/12, lg: 9/12, xl: 9/12}
    }
}

export default selectQuestionnaireStyles