import * as React from "react"

//material
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { Button, Card, CardContent, CircularProgress, Grid } from "@mui/material"
import { PaymentOutlined } from "@mui/icons-material"

//router
import { useNavigate } from "react-router-dom"

//apollo
import { useQuery } from "@apollo/client"
import { I_PAID, LOAD_ALL_PAYMENTS } from "../../queries/auth/userSubscriptionsQueries"

//styles
import quizLayoutStyles from "../../assets/styles/quizLayoutStyles"

//utils
import selectQuestionnaireStyles from "../../assets/styles/selectQuestionnaireStyles"
import { whiteColor } from "../../assets/styles/utils"

//dates
import { format } from "date-fns"

//redux
import { useSelector } from "react-redux"

export default function UserSubscriptionPayment() {
    const navigate = useNavigate()
    const authUser = useSelector((state) => state.loginReducer)

    const { data, loading } = useQuery(I_PAID, { fetchPolicy: "network-only" })
    const { data: allPaymentsData, loading: loadingPayments } = useQuery(LOAD_ALL_PAYMENTS, {
        variables: {
            clientId: authUser.client.id,
        },
        fetchPolicy: "network-only",
    })

    const TableHeaderBar = () => {
        return (
            <AppBar position="static" color={"transparent"}>
                <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box>
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{
                                mr: 2,
                                fontFamily: "monospace",
                                fontWeight: 700,
                                letterSpacing: ".3rem",
                                color: "inherit",
                            }}
                        >
                            Subscription
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                        {(loading || loadingPayments) ? (
                            <CircularProgress size={24} />
                        ) : (
                            <Button
                                size="small"
                                color={"primary"}
                                variant={"contained"}
                                onClick={() => navigate("/quiz/subscription/pay")}
                                startIcon={<PaymentOutlined />}
                            >
                                Pay
                            </Button>
                        )}
                    </Box>
                </Toolbar>
            </AppBar>
        )
    }

    return (
        <Box sx={{ width: "100%" }}>
            <Card variant={"outlined"} sx={quizLayoutStyles.componentCardContainer}>
                <TableHeaderBar />
                <CardContent>
                    <Grid container item xs={12} spacing={1}>
                        <Grid container item xs={12} spacing={1}>
                            <Grid item sx={selectQuestionnaireStyles.leftFlex}>
                                Status:
                            </Grid>
                            <Grid item sx={selectQuestionnaireStyles.rightFlex}>
                                <Box
                                    sx={{
                                        p: "3px 5px",
                                        borderRadius: "3px",
                                        color: whiteColor,
                                        backgroundColor: data?.iPaid?.isActive ? "success.main" : "error.main",
                                    }}
                                >
                                    {data?.iPaid?.isActive ? "active" : "inactive"}
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container item xs={12} spacing={1}>
                            <Grid item sx={selectQuestionnaireStyles.leftFlex}>
                                Subscription:
                            </Grid>
                            <Grid item sx={selectQuestionnaireStyles.rightFlex}>
                                {data?.iPaid?.isActive && data?.iPaid?.type ? data?.iPaid?.type : "-"}
                            </Grid>
                        </Grid>

                        <Grid container item xs={12} spacing={1}>
                            <Grid item sx={selectQuestionnaireStyles.leftFlex}>
                                Active until:
                            </Grid>
                            <Grid item sx={selectQuestionnaireStyles.rightFlex}>
                                {data?.iPaid?.isActive && data?.iPaid?.subscriptionActiveUntil
                                    ? format(new Date(data?.iPaid?.subscriptionActiveUntil), "yyyy/MM/dd hh:mm")
                                    : "-"}
                            </Grid>
                        </Grid>

                        {!!allPaymentsData?.allPayments?.edges && (
                            <Grid item xs={12} container rowSpacing={1}>
                                <Grid item xs={12} fontWeight={600}>
                                    Payment records:
                                </Grid>

                                <Grid item xs={12} container rowSpacing={1}>
                                    {allPaymentsData.allPayments.edges.map((item) => (
                                        <Grid item xs={12} key={item.node.id}>
                                            {item.node.value} {item.node.currencyCode} on{" "}
                                            {format(new Date(item.node.transactionDate), "yyyy/MM/dd hh:mm")}
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    )
}
