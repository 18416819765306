import {
    ChangeCircleOutlined,
    ClearOutlined,
    CloseOutlined,
    SearchOffOutlined,
    SearchOutlined,
} from "@mui/icons-material"
import {
    Button,
    Card,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Typography,
} from "@mui/material"
import React from "react"

export default function QuestionnaireQuestionSelect({
    open,
    handleClose,
    possibleQuestions,
    handleAddQuestions,
}) {
    const [selectedQuestionsId, setSelectedQuestionsId] = React.useState([])
    const [filterValue, setFilterValue] = React.useState("")

    const handleChangeFilterValue = (e) => {
        setFilterValue(e.target.value)
    }

    const handleSelected = (item) => {
        let selectedItems = selectedQuestionsId

        const index = selectedItems.findIndex((selectedItem) => selectedItem === item.node.id)

        if (index > -1) {
            selectedItems.splice(index, 1)
        } else {
            selectedItems.push(item.node.id)
        }

        setSelectedQuestionsId([...selectedItems])
    }

    const [displaySelectRandom, setDisplaySelectRandom] = React.useState(false)
    const [selectRandomQuantity, setSelectRandomQuantity] = React.useState("1")

    const [displaySearchField, setDisplaySearchField] = React.useState(false)

    const handleChangeSelectRandomQuantity = (e) => setSelectRandomQuantity(e.target.value)

    function selectRandomQuestions() {
        if (selectRandomQuantity) {
            let selectableQuestions = possibleQuestions.filter(
                (item) =>
                    !selectedQuestionsId.includes(item.node.id)
            )
            
            const newQuestionIds = []

            for (let i = 1; i <= +selectRandomQuantity; i++) {
                if (selectableQuestions.length) {
                    const randomIndex = Math.ceil(Math.random() * (selectableQuestions.length - 1))

                    const removedQuestion = selectableQuestions.splice(randomIndex, 1)

                    newQuestionIds.push(removedQuestion[0].node.id)
                }
            }

            setSelectedQuestionsId([...selectedQuestionsId, ...newQuestionIds])

            setSelectRandomQuantity("1")
        }
    }

    function addSelectedQuestions() {
        handleAddQuestions(selectedQuestionsId)
        handleClose()
    }

    return (
        <Dialog open={open} onClose={handleClose} fullScreen>
            <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
                padding={1}
                sx={{ backgroundColor: "primary.main", color: "white" }}
            >
                <Typography variant="subtitle1">Select questions</Typography>

                <IconButton onClick={handleClose}>
                    <CloseOutlined fontSize="small" sx={{ color: "white" }} />
                </IconButton>
            </Grid>

            <DialogContent sx={{ padding: "10px" }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} container>
                        <Grid item xs={12}>
                            <Typography variant={"subtitle2"}>
                                {possibleQuestions.length} available | {selectedQuestionsId.length} selected
                                <IconButton
                                    size="small"
                                    color="error"
                                    disabled={!selectedQuestionsId.length}
                                    onClick={() => setSelectedQuestionsId([])}
                                >
                                    <ClearOutlined fontSize="small" />
                                </IconButton>
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                disabled={!selectedQuestionsId.length}
                                onClick={addSelectedQuestions}
                            >
                                add selected
                            </Button>

                            {!displaySelectRandom && (
                                <IconButton
                                    size="small"
                                    onClick={() => setDisplaySelectRandom(true)}
                                    sx={{ marginLeft: "25px" }}
                                >
                                    <ChangeCircleOutlined fontSize="small" />
                                </IconButton>
                            )}

                            {!displaySearchField && (
                                <IconButton
                                    size="small"
                                    onClick={() => setDisplaySearchField(true)}
                                    sx={{ marginLeft: "25px" }}
                                >
                                    <SearchOutlined fontSize="small" />
                                </IconButton>
                            )}
                        </Grid>

                        {displaySelectRandom && (
                            <Grid item xs={12} mt="5px">
                                <Card variant="outlined" sx={{ padding: "8px 5px 5px 5px" }}>
                                    <Grid container rowSpacing={1}>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                label="Quantity"
                                                name={"questionsQuantity"}
                                                size={"small"}
                                                placeholder={"quantity"}
                                                fullWidth
                                                value={selectRandomQuantity}
                                                onChange={handleChangeSelectRandomQuantity}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Button
                                                variant="outlined"
                                                size={"small"}
                                                color="primary"
                                                disabled={!selectRandomQuantity}
                                                onClick={selectRandomQuestions}
                                            >
                                                Select random
                                            </Button>

                                            <Button
                                                variant="outlined"
                                                size={"small"}
                                                color="error"
                                                onClick={() => setDisplaySelectRandom(false)}
                                                sx={{ marginLeft: "10px" }}
                                            >
                                                Close
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        )}
                    </Grid>

                    {displaySearchField && (
                        <Grid container item xs={12} mt={1}>
                            <TextField
                                name={"filterValue"}
                                variant={"outlined"}
                                size={"small"}
                                placeholder={"Question filter"}
                                value={filterValue}
                                onChange={handleChangeFilterValue}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchOutlined fontSize={"small"} />
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <IconButton
                                size="small"
                                color="error"
                                onClick={() => {
                                    setFilterValue("")
                                    setDisplaySearchField(false)
                                }}
                            >
                                <SearchOffOutlined fontSize="small" />
                            </IconButton>
                        </Grid>
                    )}

                    <Grid item xs={12} mt={1}>
                        <TableContainer sx={{ maxHeight: "calc(100vh - 190px)", overflowY: "auto" }}>
                            <Table size="small">
                                <TableBody>
                                    {possibleQuestions
                                        .filter(
                                            (item) =>
                                                item.node.topic.name
                                                    .toUpperCase()
                                                    .includes(filterValue.toUpperCase()) ||
                                                item.node.description.toUpperCase().includes(filterValue.toUpperCase())
                                        )
                                        .map((item) => (
                                            <TableRow
                                                selected={
                                                    selectedQuestionsId.findIndex(
                                                        (selectedItem) => selectedItem === item.node.id
                                                    ) > -1
                                                }
                                                key={item.node.id}
                                                onClick={() => handleSelected(item)}
                                            >
                                                <TableCell sx={{ padding: "2px" }}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography variant="caption" color={"gray"}>
                                                                {item.node.topic.name}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {item.node.description}
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
