import * as React from "react"

//material
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { Card, CardContent, Grid, Divider, Tooltip, Chip } from "@mui/material"
import { QuestionMarkOutlined, TimerOutlined } from "@mui/icons-material"

//router
import { useNavigate, useSearchParams } from "react-router-dom"

//apollo
import { useQuery } from "@apollo/client"
import { LOAD_QUESTIONNAIRE_OPTIONS } from "../../queries/admin/questionnaireQueries"

//components
import { LoadingSkeleton, TableNoData } from "../../utils/tableUtils"

//date
import { formatDuration, intervalToDuration } from "date-fns"

//styles
import selectQuestionnaireStyles from "../../assets/styles/selectQuestionnaireStyles"
import quizLayoutStyles from "../../assets/styles/quizLayoutStyles"
import { grayColor } from "../../assets/styles/utils"

export default function UserSelectQuestionnaire() {
    const navigate = useNavigate()

    const [searchParams] = useSearchParams()
    const purpose = searchParams.get("purpose")

    const { data, loading } = useQuery(LOAD_QUESTIONNAIRE_OPTIONS)

    const TableHeaderBar = () => {
        return (
            <AppBar position="static" color={"transparent"}>
                <Toolbar sx={{ display: "flex", justifyContent: "space-between", overflowX: "auto" }}>
                    <Box>
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{
                                mr: 2,
                                fontFamily: "monospace",
                                fontWeight: 700,
                                letterSpacing: ".3rem",
                                color: "inherit",
                                textDecoration: "none",
                            }}
                        >
                            Select questionnaire
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
        )
    }

    const QuestionnaireItem = ({ item }) => {
        function handleSelectQuestionnaire(item) {
            navigate(`/quiz/questionnaire/${item.id}`)
        }

        return (
            <Card
                variant={"outlined"}
                sx={selectQuestionnaireStyles.card}
                onClick={() => handleSelectQuestionnaire(item)}
            >
                <CardContent style={{ padding: "5px" }}>
                    <Grid container rowSpacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{item.description}</Typography>
                        </Grid>

                        <Grid item xs={12} container>
                            <Typography variant="caption">{item.topic.name}</Typography>
                        </Grid>

                        <Grid item xs={12} container>
                            {item.examPurpose.edges.map((item) => (
                                <Chip
                                    key={item.node.id}
                                    label={item.node.name}
                                    size="small"
                                    color="warning"
                                    sx={{ mr: 1, color: "white" }}
                                />
                            ))}
                        </Grid>

                        <Grid item xs={12} container justifyContent={"flex-end"}>
                            <Box
                                display={"flex"}
                                alignItems={"center"}
                                sx={{
                                    color: "white",
                                    p: "1px",
                                    borderRadius: "3px",
                                    alignItems: "center",
                                    backgroundColor: grayColor[0],
                                    fontSize: "14px",
                                }}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <Tooltip
                                    title={`Time to complete: ${formatDuration(
                                        intervalToDuration({
                                            start: 0,
                                            end: item.time * 1000,
                                        })
                                    )}`}
                                >
                                    <TimerOutlined sx={{ fontSize: "16px", margin: "0 3px" }} />
                                </Tooltip>
                                {intervalToDuration({
                                    start: 0,
                                    end: item.time * 1000,
                                }).minutes +
                                    ":" +
                                    intervalToDuration({ start: 0, end: item.time * 1000 }).seconds}
                                <Divider
                                    orientation={"vertical"}
                                    sx={{
                                        margin: "2px 4px",
                                        display: "inline-flex",
                                        width: 0,
                                        height: "18px",
                                        border: 0,
                                        borderLeft: 2,
                                    }}
                                />
                                {item.questionnaireQuestionnaireQuestion.edges.length}
                                <Tooltip
                                    title={`Questions quantity: ${item.questionnaireQuestionnaireQuestion.edges.length}`}
                                >
                                    <QuestionMarkOutlined sx={{ fontSize: "16px", margin: "0 3px" }} />
                                </Tooltip>
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        )
    }

    return (
        <Box sx={{ width: "100%" }}>
            <Card variant={"outlined"} sx={quizLayoutStyles.componentCardContainer}>
                <TableHeaderBar />
                <CardContent>
                    {loading ? (
                        <LoadingSkeleton />
                    ) : !data?.allQuestionnaires?.edges?.length ? (
                        <TableNoData />
                    ) : (
                        <Grid container spacing={1} justifyContent={"center"} alignItems={"flex-start"}>
                            {data.allQuestionnaires.edges
                                .filter(
                                    (item) =>
                                        !purpose ||
                                        !!item.node.examPurpose.edges.find(
                                            (purposeItem) =>
                                                purposeItem.node.name.toUpperCase() === purpose.toUpperCase()
                                        )
                                )
                                .map((item) => (
                                    <Grid
                                        key={item.node.id}
                                        container
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        justifyContent={"center"}
                                    >
                                        <QuestionnaireItem item={item.node} />
                                    </Grid>
                                ))}
                        </Grid>
                    )}
                </CardContent>
            </Card>
        </Box>
    )
}
