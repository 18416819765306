import * as React from "react"

//material
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import {
    Button,
    Card,
    CardContent,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    List,
    ListItem,
    ListItemText,
    TableSortLabel,
    TextField,
} from "@mui/material"
import {
    AddOutlined,
    CancelOutlined,
    ChangeCircleOutlined,
    DeleteOutlined,
    Done,
    EditOutlined,
    ExpandLessOutlined,
    ExpandMoreOutlined,
    FeedbackOutlined,
    SearchOffOutlined,
    SearchOutlined,
} from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import TableFooter from "@mui/material/TableFooter"
import TablePagination from "@mui/material/TablePagination"
import { visuallyHidden } from "@mui/utils"

//router
import { useNavigate } from "react-router-dom"

//apollo
import { useQuery } from "@apollo/client"
import { Mutation } from "@apollo/client/react/components"
import { LOAD_ALL_QUESTIONS, DELETE_QUESTION } from "../../../queries/admin/questionQueries"

//styles
import tableStyles from "../../../assets/styles/tableStyles"

//components
import { ErrorNotification, SuccessNotification } from "../../../components/Notifications/Notifications"

//utils
import { ConfirmTransition, LoadingSkeleton, TableNoData } from "../../../utils/tableUtils"

//styles
import quizLayoutStyles from "../../../assets/styles/quizLayoutStyles"

export default function AdminQuestion() {
    const navigate = useNavigate()

    const notifySuccess = (message) => SuccessNotification(message)
    const notifyError = (message) => ErrorNotification(message)

    //pagination
    const [order, setOrder] = React.useState("asc")
    const [orderBy, setOrderBy] = React.useState("description")
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(5)

    //save server response data (page info)
    const [totalItemsPagination, setTotalItemsPagination] = React.useState(-1)
    const [startCursorPagination, setStartCursorPagination] = React.useState(null)
    const [endCursorPagination, setEndCursorPagination] = React.useState(null)

    const [queryVariables, setQueryVariables] = React.useState({
        orderBy: order === "asc" ? [orderBy] : [`-${orderBy}`],
        descriptionFilter: null,
        explanationFilter: null,
        first: rowsPerPage,
        last: null,
        beforeCursor: null,
        afterCursor: null,
    })

    const setPaginationResponse = (data) => [
        setTotalItemsPagination(data.allQuestions.totalCount),
        setStartCursorPagination(data.allQuestions.pageInfo.startCursor),
        setEndCursorPagination(data.allQuestions.pageInfo.endCursor),
    ]

    const handleChangePage = (event, newPage) => {
        if (newPage > page) {
            setQueryVariables({
                ...queryVariables,

                first: rowsPerPage,
                last: null,
                beforeCursor: null,
                afterCursor: endCursorPagination,
            })
        } else {
            setQueryVariables({
                ...queryVariables,

                first: null,
                last: rowsPerPage,
                beforeCursor: startCursorPagination,
                afterCursor: null,
            })
        }
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)

        setQueryVariables({
            ...queryVariables,

            first: parseInt(event.target.value, 10),
            last: null,
            beforeCursor: null,
            afterCursor: null,
        })
    }

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc"
        setOrder(isAsc ? "desc" : "asc")
        setOrderBy(property)
        setPage(0)

        setQueryVariables({
            ...queryVariables,

            orderBy: order === "asc" ? [property] : [`-${property}`],
            first: rowsPerPage,
            last: null,
            beforeCursor: null,
            afterCursor: null,
        })
    }

    const handleSearchInputChange = ({ descriptionValue = null, explanationValue = null }) => {
        setPage(0)

        setQueryVariables({
            ...queryVariables,

            descriptionFilter: descriptionValue,
            explanationFilter: explanationValue,

            first: rowsPerPage,
            last: null,
            beforeCursor: null,
            afterCursor: null,
        })
    }

    //display search field
    const [showSearchInput, setShowSearchInput] = React.useState(false)
    const toggleSearchInput = () => {
        setShowSearchInput(!showSearchInput)
    }

    //search field
    const [searchValue, setSearchValue] = React.useState("")
    //search by attribute
    const [searchAttribute, setSearchAttribute] = React.useState("question")

    const handleSearchValueChange = (e) => {
        setSearchValue(e.target.value)
    }

    const { data, loading, refetch } = useQuery(LOAD_ALL_QUESTIONS, {
        variables: queryVariables,
        onCompleted: (data1) => {
            setPaginationResponse(data1)
        },
        fetchPolicy: "network-only",
    })

    const TableHeaderBar = (props) => {
        const {
            handleSearchInputChange,
            searchValue,
            searchAttribute,
            setSearchAttribute,
            handleSearchValueChange,
            showSearchInput,
            toggleSearchInput,
        } = props

        const handlePressEnter = (e) => {
            if (e.keyCode === 13) {
                applySearch()
            }
        }

        const applySearch = () => {
            let params = {}
            if (searchAttribute === "question") {
                params.descriptionValue = searchValue
                params.explanationValue = null
            } else {
                params.descriptionValue = null
                params.explanationValue = searchValue
            }
            handleSearchInputChange(params)
            toggleSearchInput()
        }

        const cancelFilters = () => {
            setSearchValue("")
            const params = { descriptionValue: null, explanationValue: null }
            handleSearchInputChange(params)
            toggleSearchInput()
        }

        function handleChangeSearchAttribute(e) {
            setSearchAttribute((prevState) => (prevState === "question" ? "explanation" : "question"))
        }

        return (
            <AppBar position="static" color={"transparent"}>
                <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
                    {showSearchInput ? (
                        <TextField
                            placeholder={`Search by ${searchAttribute}...`}
                            autoFocus
                            size={"small"}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <Tooltip title={"Cancel search"}>
                                        <IconButton size={"small"} onClick={cancelFilters}>
                                            <CancelOutlined />
                                        </IconButton>
                                    </Tooltip>
                                ),
                                startAdornment: (
                                    <>
                                        <Tooltip title={"Toggle search attribute"}>
                                            <IconButton
                                                size={"small"}
                                                color={searchAttribute === "explanation" ? "primary" : "default"}
                                                onClick={handleChangeSearchAttribute}
                                            >
                                                <ChangeCircleOutlined />
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title={`Search by ${searchAttribute}`}>
                                            <IconButton size={"small"} onClick={applySearch}>
                                                <SearchOutlined />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                ),
                            }}
                            value={searchValue}
                            onChange={handleSearchValueChange}
                            onKeyDown={handlePressEnter}
                        />
                    ) : (
                        <>
                            <Box>
                                <Typography
                                    variant="h6"
                                    noWrap
                                    sx={{
                                        mr: 2,
                                        fontFamily: "monospace",
                                        fontWeight: 700,
                                        letterSpacing: ".3rem",
                                        color: "inherit",
                                        textDecoration: "none",
                                    }}
                                >
                                    Questions
                                </Typography>
                            </Box>

                            <Box sx={{ display: "flex" }}>
                                <Tooltip title="Filter data">
                                    <IconButton
                                        aria-label="filter data"
                                        color={searchValue ? "secondary" : "default"}
                                        onClick={toggleSearchInput}
                                    >
                                        {searchValue ? <SearchOutlined /> : <SearchOffOutlined />}
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Add question">
                                    <IconButton onClick={() => navigate("/quiz/manage-questions/form")}>
                                        <AddOutlined />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </>
                    )}
                </Toolbar>
            </AppBar>
        )
    }

    function EnhancedTableHead(props) {
        const { order, orderBy, onRequestSort } = props

        const headCells = [
            {
                id: "description",
                label: "Question",
                sort: true,
            },
            {
                id: "usage",
                label: "Usage",
                sort: false,
            },
        ]

        const createSortHandler = (property) => () => {
            onRequestSort(property)
        }

        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={"left"}
                            padding={"normal"}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            {headCell.sort ? (
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : "asc"}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === "desc" ? "sorted descending" : "sorted ascending"}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                            ) : (
                                headCell.label
                            )}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        )
    }

    const updateItem = (e, item) => {
        e.stopPropagation()
        navigate("/quiz/manage-questions/form", { state: { updateItem: item } })
    }

    const [openDialog, setOpenDialog] = React.useState(false)
    const [deleteItemIndex, setDeleteItemIndex] = React.useState(null)

    const deleteItem = (e, item) => {
        e.stopPropagation()
        setDeleteItemIndex(item.node.id)
        setOpenDialog(true)
    }

    function ConfirmDialog(props) {
        const { register, refetch, loading, openDialog, setOpenDialog } = props

        const handleClose = () => {
            setOpenDialog(false)
        }

        const deleteItem = () =>
            register({
                variables: {
                    id: deleteItemIndex,
                },
            }).then(
                (response) => {
                    if (response.data.deleteQuestion.found) {
                        notifySuccess("Question deleted")
                        refetch()
                    }
                    handleClose()
                },
                () => {
                    notifyError("Delete question failed, try again")
                    handleClose()
                }
            )

        return (
            <div>
                <Dialog
                    open={openDialog}
                    TransitionComponent={ConfirmTransition}
                    keepMounted
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle>Delete question</DialogTitle>
                    <DialogContent dividers>
                        <Typography gutterBottom>Do you confirm to delete the question?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="secondary" disabled={loading}>
                            Cancel
                        </Button>
                        <LoadingButton color={"primary"} variant={"contained"} loading={loading} onClick={deleteItem}>
                            Confirm
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }

    const [selectedIndex, setSelectedIndex] = React.useState(null)
    const [expandIndex, setExpandIndex] = React.useState(null)
    function handleSelect(rowIndex) {
        if (rowIndex === selectedIndex) return setSelectedIndex(null)

        return setSelectedIndex(rowIndex)
    }
    function handleExpand(rowIndex) {
        if (rowIndex === expandIndex) return setExpandIndex(null)

        return setExpandIndex(rowIndex)
    }

    //questions dialog
    const [openFeedbackDialog, setOpenFeedbackDialog] = React.useState(false)
    const [dialogFeedbacks, setDialogFeedbacks] = React.useState(null)

    function handleOpenQuestionsDialog(e, row) {
        e.stopPropagation()
        setDialogFeedbacks(row.node.questionFeedback.edges)
        setOpenFeedbackDialog(true)
    }

    function FeedbackDialog(props) {
        const { open, setOpenDialog, feedbacks } = props

        const handleClose = () => {
            setOpenDialog(false)
        }

        return (
            <Dialog
                open={open}
                TransitionComponent={ConfirmTransition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="questions-dialog"
                aria-describedby="questions-dialog"
            >
                <DialogTitle>Question feedback(s)</DialogTitle>
                <DialogContent dividers sx={{ p: "0px 16px" }}>
                    {feedbacks && (
                        <List>
                            {feedbacks.map((item) => (
                                <ListItem key={item.node.id} divider sx={{ p: 0 }}>
                                    <ListItemText
                                        primary={item.node.feedback}
                                        secondary={item.node.client.user.username}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <Mutation mutation={DELETE_QUESTION}>
            {(register, { loading: loadingDelete }) => (
                <Box sx={{ width: "100%" }}>
                    <ConfirmDialog
                        openDialog={openDialog}
                        setOpenDialog={setOpenDialog}
                        register={register}
                        refetch={refetch}
                        loading={loadingDelete}
                    />

                    <FeedbackDialog
                        open={openFeedbackDialog}
                        setOpenDialog={setOpenFeedbackDialog}
                        feedbacks={dialogFeedbacks}
                    />

                    <Card variant={"outlined"} sx={quizLayoutStyles.componentCardContainer}>
                        <TableHeaderBar
                            handleSearchInputChange={handleSearchInputChange}
                            searchValue={searchValue}
                            searchAttribute={searchAttribute}
                            setSearchAttribute={setSearchAttribute}
                            handleSearchValueChange={handleSearchValueChange}
                            showSearchInput={showSearchInput}
                            toggleSearchInput={toggleSearchInput}
                        />
                        <CardContent>
                            {loading ? (
                                <LoadingSkeleton />
                            ) : !data?.allQuestions?.edges?.length ? (
                                <TableNoData />
                            ) : (
                                <TableContainer sx={{ maxHeight: "calc(65vh)" }}>
                                    <Table aria-labelledby="tableTitle" size={"small"}>
                                        <EnhancedTableHead
                                            order={order}
                                            orderBy={orderBy}
                                            onRequestSort={handleRequestSort}
                                        />
                                        <TableBody>
                                            {data?.allQuestions?.edges?.map((row) => {
                                                return (
                                                    <React.Fragment key={row.node.id}>
                                                        <TableRow
                                                            hover
                                                            tabIndex={-1}
                                                            sx={tableStyles.row}
                                                            selected={selectedIndex === row.node.id}
                                                            onClick={() => handleSelect(row.node.id)}
                                                        >
                                                            <TableCell padding="none">
                                                                <Grid container>
                                                                    <Grid item xs={12}>
                                                                        <Typography variant={"caption"}>
                                                                            {row.node.topic.name}
                                                                        </Typography>
                                                                    </Grid>

                                                                    <Grid item xs={12}>
                                                                        {row.node.description
                                                                            ? row.node.description.length > 50
                                                                                ? row.node.description.substring(
                                                                                      0,
                                                                                      50
                                                                                  ) + "..."
                                                                                : row.node.description
                                                                            : "-"}
                                                                    </Grid>
                                                                </Grid>
                                                            </TableCell>

                                                            <TableCell padding="none">
                                                                <Grid container>
                                                                    <Grid item xs={12}>
                                                                        Answers:{" "}
                                                                        {row.node.questionPossibleAnswer.edges.length}
                                                                    </Grid>

                                                                    <Grid item xs={12}>
                                                                        <small>
                                                                            {
                                                                                row.node.questionPossibleAnswer.edges.filter(
                                                                                    (item) => item.node.isCorrect
                                                                                ).length
                                                                            }{" "}
                                                                            correct
                                                                        </small>
                                                                    </Grid>

                                                                    {!!row.node.questionUrl.edges.length && (
                                                                        <Grid item xs={12}>
                                                                            Urls: {row.node.questionUrl.edges.length}
                                                                        </Grid>
                                                                    )}

                                                                    {!!row.node.questionCite.edges.length && (
                                                                        <Grid item xs={12}>
                                                                            Cites: {row.node.questionCite.edges.length}
                                                                        </Grid>
                                                                    )}

                                                                    {!!row.node.questionFeedback.edges.length && (
                                                                        <Grid item xs={12}>
                                                                            Feedbacks:{" "}
                                                                            {row.node.questionFeedback.edges.length}
                                                                            <Tooltip title={"Question feedback"}>
                                                                                <div>
                                                                                    <IconButton
                                                                                        size={"small"}
                                                                                        sx={{ m: "3px" }}
                                                                                        disabled={
                                                                                            row.node.questionFeedback
                                                                                                .edges.length === 0
                                                                                        }
                                                                                        onClick={(e) =>
                                                                                            handleOpenQuestionsDialog(
                                                                                                e,
                                                                                                row
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <FeedbackOutlined
                                                                                            fontSize={"small"}
                                                                                        />
                                                                                    </IconButton>
                                                                                </div>
                                                                            </Tooltip>
                                                                        </Grid>
                                                                    )}
                                                                </Grid>
                                                            </TableCell>

                                                            <TableCell padding="none">
                                                                <Box sx={tableStyles.actionColumn}>
                                                                    <Tooltip title={"Details"}>
                                                                        <IconButton
                                                                            size={"small"}
                                                                            sx={{ m: "3px" }}
                                                                            onClick={() => handleExpand(row.node.id)}
                                                                        >
                                                                            {expandIndex === row.node.id ? (
                                                                                <ExpandLessOutlined />
                                                                            ) : (
                                                                                <ExpandMoreOutlined />
                                                                            )}
                                                                        </IconButton>
                                                                    </Tooltip>

                                                                    <Tooltip title={"Edit"}>
                                                                        <IconButton
                                                                            color={"warning"}
                                                                            size={"small"}
                                                                            sx={{ m: "3px" }}
                                                                            onClick={(e) => updateItem(e, row.node)}
                                                                        >
                                                                            <EditOutlined fontSize={"small"} />
                                                                        </IconButton>
                                                                    </Tooltip>

                                                                    <Tooltip title={"Delete"}>
                                                                        <IconButton
                                                                            color={"error"}
                                                                            size={"small"}
                                                                            sx={{ m: "3px" }}
                                                                            onClick={(e) => deleteItem(e, row)}
                                                                        >
                                                                            <DeleteOutlined fontSize={"small"} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>

                                                        <TableRow>
                                                            <TableCell colSpan={2}>
                                                                <Collapse
                                                                    in={expandIndex === row.node.id}
                                                                    timeout="auto"
                                                                    unmountOnExit
                                                                >
                                                                    <Grid container rowSpacing={2}>
                                                                        <Grid item xs={12}>
                                                                            <Typography
                                                                                variant="subtitle1"
                                                                                gutterBottom
                                                                                component="div"
                                                                            >
                                                                                Details
                                                                            </Typography>
                                                                        </Grid>

                                                                        <Grid container item spacing={1} xs={12}>
                                                                            T: {row.node.topic.name}
                                                                        </Grid>

                                                                        <Grid container item spacing={1} xs={12}>
                                                                            Q: {row.node.description}
                                                                        </Grid>

                                                                        <Grid container item spacing={1} xs={12}>
                                                                            E: {row.node.explanation}
                                                                        </Grid>

                                                                        {row.node.questionUrl.edges.length > 0 && (
                                                                            <Grid
                                                                                container
                                                                                item
                                                                                spacing={1}
                                                                                xs={12}
                                                                                md={6}
                                                                            >
                                                                                <Grid
                                                                                    item
                                                                                    xs={12}
                                                                                    sx={{ fontWeight: 600 }}
                                                                                >
                                                                                    URL(s):
                                                                                </Grid>
                                                                                {row.node.questionUrl.edges.map(
                                                                                    (item, index) => (
                                                                                        <Grid
                                                                                            container
                                                                                            item
                                                                                            spacing={1}
                                                                                            xs={12}
                                                                                            key={item.node.id}
                                                                                            alignItems={"center"}
                                                                                        >
                                                                                            <Grid item xs={12}>
                                                                                                {`${index + 1}. `}
                                                                                                <a
                                                                                                    href={item.node.url}
                                                                                                    target={"_blank"}
                                                                                                    rel={
                                                                                                        "noopener noreferrer"
                                                                                                    }
                                                                                                >
                                                                                                    {item.node.url}
                                                                                                </a>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    )
                                                                                )}
                                                                            </Grid>
                                                                        )}

                                                                        {row.node.questionCite.edges.length > 0 && (
                                                                            <Grid
                                                                                container
                                                                                item
                                                                                spacing={1}
                                                                                xs={12}
                                                                                md={6}
                                                                            >
                                                                                <Grid
                                                                                    item
                                                                                    xs={12}
                                                                                    sx={{ fontWeight: 600 }}
                                                                                >
                                                                                    Cite(s):
                                                                                </Grid>
                                                                                {row.node.questionCite.edges.map(
                                                                                    (item, index) => (
                                                                                        <Grid
                                                                                            container
                                                                                            item
                                                                                            spacing={1}
                                                                                            xs={12}
                                                                                            key={item.node.id}
                                                                                            alignItems={"center"}
                                                                                        >
                                                                                            <Grid item xs={12}>
                                                                                                {index + 1}.{" "}
                                                                                                {item.node.cite}
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    )
                                                                                )}
                                                                            </Grid>
                                                                        )}

                                                                        <Grid container item spacing={1} xs={12}>
                                                                            <Grid item xs={12} sx={{ fontWeight: 600 }}>
                                                                                Answers:
                                                                            </Grid>
                                                                            {row.node.questionPossibleAnswer.edges.map(
                                                                                (item, index) => (
                                                                                    <Grid
                                                                                        container
                                                                                        item
                                                                                        spacing={1}
                                                                                        xs={12}
                                                                                        key={item.node.id}
                                                                                        alignItems={"center"}
                                                                                    >
                                                                                        <Grid item xs={"auto"}>
                                                                                            {index + 1}.{" "}
                                                                                            {item.node.description}
                                                                                        </Grid>
                                                                                        <Grid item xs={"auto"}>
                                                                                            {item.node.isCorrect && (
                                                                                                <Tooltip
                                                                                                    title={
                                                                                                        "Correct answer"
                                                                                                    }
                                                                                                >
                                                                                                    <Done
                                                                                                        fontSize={
                                                                                                            "small"
                                                                                                        }
                                                                                                    />
                                                                                                </Tooltip>
                                                                                            )}
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                )
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Collapse>
                                                            </TableCell>
                                                        </TableRow>
                                                    </React.Fragment>
                                                )
                                            })}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[5, 10, 25]}
                                                    colSpan={6}
                                                    SelectProps={{
                                                        inputProps: {
                                                            "aria-label": "rows per page",
                                                        },
                                                    }}
                                                    count={totalItemsPagination}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            )}
                        </CardContent>
                    </Card>
                </Box>
            )}
        </Mutation>
    )
}
