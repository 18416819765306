import React from "react"

//material ui
import { Box, Card, CardContent, CircularProgress, Grid, Typography } from "@mui/material"
import {
    CreditCardOutlined,
    VerifiedUserOutlined
} from "@mui/icons-material"

//styles
import statsCardStyles from "../../assets/styles/statsCardStyles"

//apollo
import { useQuery } from "@apollo/client"
import { LOAD_ALL_USERS } from "../../queries/admin/adminStatsQueries"

//notification
import { ErrorNotification } from "../../components/Notifications/Notifications"

export default function AdminDashboard() {
    const notifyError = message => ErrorNotification(message)

    const [systemUsers, setSystemUsers] = React.useState("-")
    const [verifiedUsers, setVerifiedUsers] = React.useState("-")
    const [adminUsers, setAdminUsers] = React.useState("-")

    const [activeUsers, setActiveUsers] = React.useState("-") //user with client created (accessed the system at least one time)
    const [activeSubscriptionUsers, setActiveSubscriptionUsers] = React.useState("-") //active users with active subscription
    const [activeSubscriptionUsersM, setActiveSubscriptionUsersM] = React.useState("-") //1 month active subscriptions
    const [activeSubscriptionUsersS, setActiveSubscriptionUsersS] = React.useState("-") //1 semester active subscriptions
    const [activeSubscriptionUsersY, setActiveSubscriptionUsersY] = React.useState("-") //1 year active subscriptions
    const [activeSubscriptionUsersU, setActiveSubscriptionUsersU] = React.useState("-") //unlimited active subscriptions

    const { loading: loadingUsers } = useQuery(
        LOAD_ALL_USERS,
        {
            onCompleted: data => {
                if (data?.allUsers?.edges) {
                    setSystemUsers(data.allUsers.edges.length)

                    let systemVerifiedUsers = 0
                    let adminRoleUsers = 0
                    let activeUser = 0
                    let activePaymentUser = 0
                    let activePaymentUserM = 0
                    let activePaymentUserS = 0
                    let activePaymentUserY = 0
                    let activePaymentUserU = 0

                    data.allUsers.edges.forEach(item => {
                        if (item.node.isVerified) {
                            systemVerifiedUsers++
                        }

                        if (item.node.isStaff || item.node.isSuperuser) {
                            adminRoleUsers++
                        }

                        if (item.node.client?.id) {
                            activeUser++

                            if (item.node.isActivePayment) {
                                activePaymentUser++

                                switch (item.node.client.paymentType.toUpperCase()) {
                                    case "M":
                                        activePaymentUserM++
                                        break

                                    case "S":
                                        activePaymentUserS++
                                        break

                                    case "Y":
                                        activePaymentUserY++
                                        break

                                    case "U":
                                        activePaymentUserU++
                                        break

                                    default:
                                        break
                                }
                            }
                        }
                    })

                    setVerifiedUsers(systemVerifiedUsers)
                    setAdminUsers(adminRoleUsers)

                    setActiveUsers(activeUser)
                    setActiveSubscriptionUsers(activePaymentUser)

                    setActiveSubscriptionUsersM(activePaymentUserM)
                    setActiveSubscriptionUsersS(activePaymentUserS)
                    setActiveSubscriptionUsersY(activePaymentUserY)
                    setActiveSubscriptionUsersU(activePaymentUserU)
                }
            },
            fetchPolicy: "network-only",
            onError: () => {
                notifyError("Error loading users data")
            }
        }
    )

    const Stats1Card = () => (
        <Card sx={statsCardStyles.cardContainer}>
            <Box sx={statsCardStyles.cardHeader}>
                <VerifiedUserOutlined fontSize={"large"} sx={statsCardStyles.cardHeaderIconTraining}/>
            </Box>
            {
                loadingUsers
                    ? <Grid container justifyContent={"center"} alignItems={"center"} sx={{height: "160px"}}>
                        <CircularProgress color={"primary"} size={24}/>
                    </Grid>
                    : <>
                        <CardContent sx={statsCardStyles.cardContent}>
                            <Grid container>
                                <Grid container item xs={12} justifyContent={"right"}>
                                    <Typography variant={"h5"}>
                                        {systemUsers} <small>register users</small>
                                    </Typography>
                                </Grid>

                                <Grid container item xs={12} justifyContent={"right"}>
                                    <Typography variant={"h6"}>
                                        {verifiedUsers} <small>verified</small>
                                    </Typography>
                                </Grid>

                                <Grid container item xs={12} justifyContent={"right"}>
                                    <Typography variant={"h6"}>
                                        {adminUsers} <small>admins</small>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </>
            }
        </Card>
    )

    const Stats2Card = () => (
        <Card sx={statsCardStyles.cardContainer}>
            <Box sx={statsCardStyles.cardHeader}>
                <CreditCardOutlined fontSize={"large"} sx={statsCardStyles.cardHeaderIconTraining}/>
            </Box>
            {
                loadingUsers
                    ? <Grid container justifyContent={"center"} alignItems={"center"} sx={{height: "160px"}}>
                        <CircularProgress color={"primary"} size={24}/>
                    </Grid>
                    : <>
                        <CardContent sx={statsCardStyles.cardContent}>
                            <Grid container>
                                <Grid container item xs={12} justifyContent={"right"}>
                                    <Typography variant={"h5"}>
                                        {activeUsers} <small>active users</small>
                                    </Typography>
                                </Grid>

                                <Grid container item xs={12} justifyContent={"right"}>
                                    <Typography variant={"h6"}>
                                        {activeSubscriptionUsers} <small>subscription active users</small>
                                    </Typography>
                                </Grid>

                                <Grid container item xs={12} justifyContent={"right"}>
                                    <Typography variant={"h6"}>
                                        {activeSubscriptionUsersM} <small>monthly</small>
                                    </Typography>
                                </Grid>

                                <Grid container item xs={12} justifyContent={"right"}>
                                    <Typography variant={"h6"}>
                                        {activeSubscriptionUsersS} <small>semester</small>
                                    </Typography>
                                </Grid>

                                <Grid container item xs={12} justifyContent={"right"}>
                                    <Typography variant={"h6"}>
                                        {activeSubscriptionUsersY} <small>year</small>
                                    </Typography>
                                </Grid>

                                <Grid container item xs={12} justifyContent={"right"}>
                                    <Typography variant={"h6"}>
                                        {activeSubscriptionUsersU} <small>forever</small>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </>
            }
        </Card>
    )

    return (
        <Box sx={{width: 1}}>
            <Grid container rowSpacing={6} columnSpacing={3} justifyContent={"center"}>
                <Grid container item xs={12} sm={6} alignItems={"center"} justifyContent={"center"}>
                    <Stats1Card/>
                </Grid>

                <Grid container item xs={12} sm={6} alignItems={"center"} justifyContent={"center"}>
                    <Stats2Card/>
                </Grid>
            </Grid>
        </Box>
    )
}