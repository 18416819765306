import React from "react"

import {
    Box,
    Card,
    CardContent,
    CircularProgress,
    Grid
} from "@mui/material"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"

import { useQuery } from "@apollo/client"

//notification
import { VERIFY_ACCOUNT } from "../../queries/auth/userQueries"

import { useNavigate, useParams } from "react-router-dom"
import { ErrorNotification, SuccessNotification } from "../../components/Notifications/Notifications"

export default function VerifyAccountPage(props) {
    const navigate = useNavigate()
    const { token } = useParams()

    const notifyError = message => ErrorNotification(message)
    const notifySuccess = message => SuccessNotification(message)

    const {loading} = useQuery(
        VERIFY_ACCOUNT,
        {
            variables: {token: token},
            onCompleted: response => {
                if (response.verifyAccount) {
                    notifySuccess("Account verified correctly. Now you can log in")
                } else {
                    notifyError("Account verification failed. Contact support for help")
                }

                navigate("/")
            },
            onError: () => {
                notifyError("Account verification failed. Contact support for help")
                navigate("/")
            }
        }
    )

    const TableHeaderBar = () => {
        return (
            <AppBar position="static">
                <Toolbar disableGutters sx={{p: 1, display: "flex", alignItems: "center", justifyContent: 'space-between'}}>
                    <Typography
                        variant="h6"
                        noWrap
                        sx={{
                            mr: 2,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        Verifying account
                    </Typography>
                </Toolbar>
            </AppBar>
        )
    }

    return (
        <Box sx={{width: "100%", mt: 3, display: "flex", alignItems: "center", justifyContent: "center"}}>
            <Card variant={"elevation"} sx={{width: {xs: 1, sm: 1, md: 3/4, lg: 1/2, xl: 1/3}}}>
                <TableHeaderBar/>
                <CardContent>
                    <Grid container justifyContent={"center"}>
                        { loading && <CircularProgress size={34} /> }
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    )
}