import { gql } from "@apollo/client"

//all users
export const LOAD_ALL_USERS = gql`
    query allUsersFiltered(
        $orderBy: [String]
        $first: Int
        $last: Int
        $beforeCursor: String
        $afterCursor: String
        $userFilter: String
    ) {
        allUsers(
            orderBy: $orderBy
            first: $first
            last: $last
            before: $beforeCursor
            after: $afterCursor
            username_Icontains: $userFilter
        ) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            totalCount
            edges {
                node {
                    id
                    username
                    firstName
                    lastName
                    lastLogin
                    isSuperuser
                    isStaff
                    isActive
                    dateJoined
                    email
                    isVerified
                    isActivePayment
                    client {
                        id
                        paymentType
                        nextPayment
                    }
                }
            }
        }
    }
`

//create payment
export const CREATE_PAYMENT = gql`
    mutation createPayment($client: ID!, $value: Float!) {
        createPayment(input: { client: $client, value: $value }) {
            payment {
                id
            }
        }
    }
`