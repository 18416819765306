import { gql } from "@apollo/client"

//user login with username and password
export const LOGIN = gql`
    mutation tokenAuth($username: String!, $password: String!) {
        tokenAuth(input: { username: $username, password: $password }) {
            success
            errors
            token
            refreshToken
            user {
                id
                username
                isSuperuser
                isStaff
                groups {
                    edges {
                        node {
                            id
                            name
                        }
                    }
                }
                client {
                    id
                }
            }
        }
    }
`

//update user account (without user id, user app mutation)
export const UPDATE_USER_ACCOUNT = gql`
    mutation updateAccount($firstName: String!, $lastName: String!) {
        updateAccount(input: { firstName: $firstName, lastName: $lastName }) {
            success
            errors
        }
    }
`

//reset password
export const REFRESH_TOKEN = gql`
    mutation refreshToken($refreshToken: String!) {
        refreshToken(input: { refreshToken: $refreshToken }) {
            refreshToken
            errors
            payload
            success
            token
        }
    }
`

//user device check
export const DEVICE_CHECK = gql`
    query deviceCheck {
        deviceCheck {
            isRegistered
            wasRegisteredNow
            validIdLists
        }
    }
`

//user delete device
export const DELETE_DEVICE = gql`
    mutation deleteDevice($id: ID!){
        deleteDevice(id: $id) {
            found
            deletedId
        }
    }
`

//user delete device
export const USER_DEVICES = gql`
    query allDevices($clientId: ID!){
        allDevices(clientId: $clientId) {
            edges{
                node{
                    id
                    code
                }
            }
        }
    }
`
