const cookiesAgreementStyle = {
    container: {
        minHeight: 80,
        width: "100%",
        background: "#23395d",
        border: "none",
        position: "fixed",
        left: "0",
        bottom: "0",
        padding: "15px 20px",
    },
    content: {
        fontSize: "14px",
        color: "white",
        textAlign: "center",
    },
}

export default cookiesAgreementStyle
