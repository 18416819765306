import * as React from "react"

//material ui
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { Card, CardContent, Grid, Button, Collapse, Tooltip, IconButton } from "@mui/material"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import { Cancel, Done, ExpandLessOutlined, ExpandMoreOutlined } from "@mui/icons-material"

//router
import { useNavigate, useParams } from "react-router-dom"

//apollo
import { useQuery } from "@apollo/client"
import { EXAM_ANSWER_DETAILS } from "../../queries/auth/userExamQueries"
import { LOAD_QUESTIONNAIRE_DETAILS } from "../../queries/admin/questionnaireQueries"

//notifications
import { ErrorNotification } from "../../components/Notifications/Notifications"

//components
import { LoadingSkeleton, TableNoData } from "../../utils/tableUtils"

//styles
import tableStyles from "../../assets/styles/tableStyles"
import quizLayoutStyles from "../../assets/styles/quizLayoutStyles"

export default function UserExamSummaryDetails(props) {
    const navigate = useNavigate()
    const { questionnaireId, examId } = useParams()

    const notifyError = (message) => ErrorNotification(message)

    const [data, setData] = React.useState()

    const { data: dataExamAnswers, loading: loadingExam } = useQuery(EXAM_ANSWER_DETAILS, {
        variables: { id: examId },
        onError: () => {
            notifyError("Quiz summary details load failed, try again")
        },
    })
    const { data: dataQuestionnaire, loading: loadingQuestionnaire } = useQuery(LOAD_QUESTIONNAIRE_DETAILS, {
        variables: { id: questionnaireId },
        onError: () => {
            notifyError("Quiz summary details load failed, try again")
        },
    })

    React.useEffect(() => {
        if (dataExamAnswers?.exam?.id && dataQuestionnaire?.questionnaire?.id) {
            const answers = dataExamAnswers.exam.examAnswer.edges
            const questions = dataQuestionnaire.questionnaire.questionnaireQuestionnaireQuestion.edges

            let matchedQuestions = []
            questions.forEach((itemQuestion) => {
                const answerProvided = answers.find(
                    (itemAnswer) => itemAnswer.node.questionnaireQuestion.id === itemQuestion.node.id
                )
                if (answerProvided) {
                    matchedQuestions.push({
                        ...itemQuestion.node.question,
                        selectedAnswer: answerProvided.node.possibleAnswer,
                    })
                }
            })

            setData(matchedQuestions)
        }
    }, [dataExamAnswers, dataQuestionnaire])

    const TableHeaderBar = () => {
        return (
            <AppBar position="static" color={"transparent"}>
                <Toolbar>
                    <Box>
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{
                                mr: 2,
                                fontFamily: "monospace",
                                fontWeight: 700,
                                letterSpacing: ".3rem",
                                color: "inherit",
                                textDecoration: "none",
                            }}
                        >
                            answers details
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
        )
    }

    function EnhancedTableHead(props) {
        const headCells = [
            {
                id: "number",
                label: "#",
            },
            {
                id: "question",
                label: "Question",
            },
            {
                id: "answer",
                label: "Answered",
            },
        ]

        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell key={headCell.id} align={"left"} padding={"normal"}>
                            {headCell.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        )
    }

    function getPossibleAnswerItem(possibleAnswers, selectedAnswer) {
        return possibleAnswers.edges.find((item) => item.node.id === selectedAnswer.id)
    }

    //expand description
    const [expandIndex, setExpandIndex] = React.useState(null)
    function handleExpand(rowIndex) {
        if (rowIndex === expandIndex) return setExpandIndex(null)

        return setExpandIndex(rowIndex)
    }

    return (
        <Box sx={{ width: "100%" }}>
            <Card variant={"outlined"} sx={quizLayoutStyles.componentCardContainer}>
                <TableHeaderBar />
                <CardContent>
                    <Grid container spacing={1}>
                        {loadingExam || loadingQuestionnaire ? (
                            <LoadingSkeleton />
                        ) : !data?.length ? (
                            <TableNoData />
                        ) : (
                            <TableContainer sx={{ maxHeight: "calc(70vh)" }}>
                                <Table aria-labelledby="tableTitle" size={"small"}>
                                    <EnhancedTableHead />
                                    <TableBody>
                                        {data.map((row, index) => {
                                            return (
                                                <React.Fragment key={row.id}>
                                                    <TableRow
                                                        hover
                                                        tabIndex={-1}
                                                        key={row.id}
                                                        sx={tableStyles.row}
                                                        onClick={() => handleExpand(index)}
                                                    >
                                                        <TableCell padding="none">{index + 1}</TableCell>
                                                        <TableCell
                                                            padding="none"
                                                            sx={{ minWidth: "200px", fontSize: "12px" }}
                                                        >
                                                            {row.description}
                                                        </TableCell>
                                                        <TableCell sx={{ minWidth: "150px", fontSize: "12px" }}>
                                                            <Grid container>
                                                                {row.selectedAnswer.isCorrect ? (
                                                                    <>
                                                                        <Grid
                                                                            item
                                                                            xs={12}
                                                                            container
                                                                            alignItems={"center"}
                                                                        >
                                                                            <Typography
                                                                                variant={"caption"}
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    whiteSpace: "nowrap",
                                                                                }}
                                                                            >
                                                                                <Done
                                                                                    color="success"
                                                                                    fontSize="small"
                                                                                />{" "}
                                                                                Correct
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            {
                                                                                getPossibleAnswerItem(
                                                                                    row.questionPossibleAnswer,
                                                                                    row.selectedAnswer
                                                                                ).node.description
                                                                            }
                                                                        </Grid>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <Grid
                                                                            item
                                                                            xs={12}
                                                                            container
                                                                            alignItems={"center"}
                                                                        >
                                                                            <Typography
                                                                                variant={"caption"}
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    whiteSpace: "nowrap",
                                                                                }}
                                                                            >
                                                                                <Cancel
                                                                                    color="error"
                                                                                    fontSize="small"
                                                                                />{" "}
                                                                                Wrong
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            {
                                                                                getPossibleAnswerItem(
                                                                                    row.questionPossibleAnswer,
                                                                                    row.selectedAnswer
                                                                                ).node.description
                                                                            }
                                                                        </Grid>
                                                                    </>
                                                                )}
                                                            </Grid>

                                                            <Box sx={tableStyles.actionColumn}>
                                                                <Tooltip title={"Details"}>
                                                                    <IconButton
                                                                        size={"small"}
                                                                        sx={{ m: "3px" }}
                                                                        onClick={() => handleExpand(index)}
                                                                    >
                                                                        {expandIndex === index ? (
                                                                            <ExpandLessOutlined />
                                                                        ) : (
                                                                            <ExpandMoreOutlined />
                                                                        )}
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell style={{ padding: 0 }} colSpan={3}>
                                                            <Collapse
                                                                in={expandIndex === index}
                                                                timeout="auto"
                                                                unmountOnExit
                                                            >
                                                                <Grid
                                                                    container
                                                                    spacing={1}
                                                                    sx={{ padding: "8px 26px" }}
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <Typography
                                                                            variant="subtitle1"
                                                                            gutterBottom
                                                                            component="div"
                                                                        >
                                                                            Details
                                                                        </Typography>
                                                                    </Grid>

                                                                    <Grid container item spacing={1} xs={12}>
                                                                        <Grid item xs={"auto"} sx={{ fontWeight: 600 }}>
                                                                            Topic:
                                                                        </Grid>
                                                                        <Grid item xs={true}>
                                                                            {row.topic.name}
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid container item spacing={1} xs={12}>
                                                                        <Grid item xs={"auto"} sx={{ fontWeight: 600 }}>
                                                                            Question:
                                                                        </Grid>
                                                                        <Grid item xs={true}>
                                                                            {row.description}
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid container item spacing={1} xs={12}>
                                                                        <Grid item xs={12} sx={{ fontWeight: 600 }}>
                                                                            Answers:
                                                                        </Grid>
                                                                        {row.questionPossibleAnswer.edges.map(
                                                                            (item, index) => (
                                                                                <Grid
                                                                                    container
                                                                                    item
                                                                                    spacing={1}
                                                                                    xs={12}
                                                                                    key={item.node.id}
                                                                                    alignItems={"center"}
                                                                                >
                                                                                    <Grid item xs={"auto"}>
                                                                                        {index + 1}.{" "}
                                                                                        {item.node.description}
                                                                                    </Grid>
                                                                                    <Grid item xs={"auto"}>
                                                                                        {item.node.isCorrect && (
                                                                                            <Tooltip
                                                                                                title={"Correct answer"}
                                                                                            >
                                                                                                <Done
                                                                                                    fontSize={"small"}
                                                                                                />
                                                                                            </Tooltip>
                                                                                        )}
                                                                                    </Grid>
                                                                                </Grid>
                                                                            )
                                                                        )}
                                                                    </Grid>
                                                                </Grid>
                                                            </Collapse>
                                                        </TableCell>
                                                    </TableRow>
                                                </React.Fragment>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Grid>

                    <Box sx={{ display: "flex", justifyContent: "right", p: 1, m: 1 }}>
                        <Button
                            type={"submit"}
                            color={"primary"}
                            variant={"contained"}
                            size={"small"}
                            sx={{ m: 1 }}
                            onClick={() => navigate(`/quiz/questionnaire/${questionnaireId}/exam/${examId}/summary`)}
                        >
                            Return
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    )
}
