import React from "react";

//material ui
import { Slide, Skeleton, Box } from "@mui/material"


export const LoadingSkeleton = () => {
    return (
        <Box sx={{width: 1}}>
            <Skeleton/>
            <Skeleton/>
            <Skeleton/>
            <Skeleton/>
            <Skeleton/>
        </Box>
    )
}

export const TableNoData = () => {
    return (
        <Box
            sx={
                {
                    width: "100%",
                    height: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#a9afbb"
                }
            }
        >
            No data to display
        </Box>
    )
}

export const ConfirmTransition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})
