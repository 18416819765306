import Cookies from "js-cookie"

export default function loginReducer(state = null, action) {
    switch (action.type) {
        case "SING_IN":
            state = Object.assign({}, action.payload.user)
            Cookies.set("JWT", action.payload.token, { secure: true, sameSite: "Strict", expires: 15 })
            localStorage.setItem("refreshToken", action.payload.refreshToken)
            return state
        case "SING_OUT":
            state = null
            Cookies.remove("JWT")
            return state
        case "CREATE_CLIENT":
            state = Object.assign(state, {client: action.payload})
            return state
        default:
            return state
    }
}
