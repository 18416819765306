import React from "react"
import { Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField } from "@mui/material"
import { CancelOutlined, Check, QuestionMark } from "@mui/icons-material"

function QuestionSelect(props) {
    const {questionStatusList, selectedQuestion, setSelectedQuestion, setSelectedAnswer} = props

    const [questionsFilterValue, setQuestionsFilterValue] = React.useState("")

    function handleSelectQuestion(item) {
        if (!item.wasAnswered) {
            setSelectedQuestion(item)

            setSelectedAnswer(null)
        }
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <TextField
                    variant={"outlined"}
                    size={"small"}
                    fullWidth
                    placeholder={"Filter"}
                    InputProps={{
                        endAdornment:
                            <IconButton size={"small"} disabled={!questionsFilterValue} onClick={() => setQuestionsFilterValue("")}>
                                <CancelOutlined fontSize={"small"}/>
                            </IconButton>
                    }}
                    value={questionsFilterValue}
                    onChange={(e) => setQuestionsFilterValue(e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <List sx={{width: 1, maxHeight: "calc(70vh - 55px)", p: "3px", overflowY: "auto"}}>
                    {
                        questionStatusList
                            .filter(
                                item => item.question.description.toLowerCase().includes(questionsFilterValue.toLowerCase())
                            )
                            .map(item => (
                            <ListItem
                                key={item.question.id}
                                divider
                                disablePadding
                                onClick={() => handleSelectQuestion(item)}
                            >
                                <ListItemButton
                                    dense
                                    disabled={item.wasAnswered}
                                    selected={item.question.id === selectedQuestion?.question?.id}
                                >
                                    <ListItemIcon sx={{minWidth: "25px"}}>
                                        {
                                            item.wasAnswered ? <Check fontSize={"small"} color={"success"}/> : <QuestionMark fontSize={"small"}/>
                                        }
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={item.question.description}
                                    />
                                </ListItemButton>
                            </ListItem>

                        ))
                    }
                </List>
            </Grid>
        </Grid>
    )
}

export default QuestionSelect