import { DragHandle, ExpandLessOutlined, ExpandMoreOutlined, RemoveCircleOutline } from "@mui/icons-material"
import { Grid, IconButton } from "@mui/material"
import React from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { grayColor } from "../../../../assets/styles/utils"

export default function QuestionnaireQuestionsRearrange({
    possibleQuestions,
    selectedQuestionsId,
    handleDropItem,
    handleDismissItem,
}) {
    const questions = selectedQuestionsId.map((item) => {
        const questions = possibleQuestions.find((questionItem) => questionItem.node.id === item)

        return {
            id: questions.node.id,
            question: questions.node.description,
        }
    })

    const QuestionItem = ({ index, id, question, provided }) => {
        const [expand, setExpand] = React.useState(false)

        return (
            <Grid
                container
                sx={{
                    backgroundColor: grayColor[5],
                    borderRadius: "5px",
                    maxHeight: expand ? "auto" : "100px",
                    position: "relative",
                    padding: "5px",
                    overflow: "clip",
                }}
                ref={provided.innerRef}
                {...provided.dragHandleProps}
                {...provided.draggableProps}
            >
                <Grid container item xs={12}>
                    <small>
                        {index + 1}. {question}
                    </small>
                </Grid>

                <DragHandle fontSize={"small"} sx={{ position: "absolute", top: -5, left: 0 }} />

                <IconButton
                    size={"small"}
                    color="error"
                    onClick={(e) => {
                        e.stopPropagation()
                        handleDismissItem(id)
                    }}
                    sx={{ position: "absolute", top: -5, right: 0 }}
                >
                    <RemoveCircleOutline fontSize={"small"} />
                </IconButton>

                {expand ? (
                    <ExpandLessOutlined
                        fontSize={"small"}
                        onClick={(e) => {
                            e.stopPropagation()
                            setExpand(false)
                        }}
                        sx={{ position: "absolute", bottom: 0, right: 0 }}
                    />
                ) : (
                    <ExpandMoreOutlined
                        fontSize={"small"}
                        onClick={(e) => {
                            e.stopPropagation()
                            setExpand(true)
                        }}
                        sx={{ position: "absolute", bottom: 0, right: 0 }}
                    />
                )}
            </Grid>
        )
    }

    return (
        <Grid container rowSpacing={1}>
            <Grid item xs={12}>
                {selectedQuestionsId.length} questions selected
            </Grid>

            <Grid item xs={12}>
                <DragDropContext onDragEnd={(droppedItem) => handleDropItem(droppedItem)}>
                    <Droppable droppableId="list-container">
                        {(provided) => (
                            <Grid
                                container
                                rowSpacing={1}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                sx={{ maxHeight: "calc(65vh - 35px)", overflowY: "auto" }}
                            >
                                {questions.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                        {(provided) => (
                                            <Grid item xs={12}>
                                                <QuestionItem
                                                    index={index}
                                                    id={item.id}
                                                    question={item.question}
                                                    provided={provided}
                                                />
                                            </Grid>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </Grid>
                        )}
                    </Droppable>
                </DragDropContext>
            </Grid>
        </Grid>
    )
}
