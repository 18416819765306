import { whiteColor } from "./utils"

const statsCardStyles = {
    cardContainer: {
        position: "relative",
        overflow: "unset",
        width: "300px",
    },

    cardHeader: {
        padding: "0 10px",
        marginBottom: "0",
        borderBottom: "none",
        background: "transparent",
        zIndex: "3 !important",
        margin: "-17px 4px 0px 4px",
        position: "absolute",
        width: "300px",
        boxSizing: "border-box",
    },

    cardHeaderIconWarning: {
        backgroundColor: "warning.main",
        color: whiteColor,
        border: "1px solid white",
        p: "10px",
    },

    cardHeaderIconTraining: {
        backgroundColor: "info.main",
        color: whiteColor,
        border: "1px solid white",
        p: "10px",
    },

    cardHeaderIconExam: {
        backgroundColor: "success.main",
        color: whiteColor,
        border: "1px solid white",
        p: "10px",
    },

    cardContent: {
        pt: "42px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    cardActions: {
        display: "flex",
        justifyContent: "right",
        alignItems: "center",
        pt: "0",
    }
}

export default statsCardStyles