import * as React from 'react';

//material
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {
    Card,
    CardContent,
    Grid,
    Button,
    Avatar,
    Divider, Fade, CircularProgress, TextField, InputAdornment
} from "@mui/material"
import {
    CancelOutlined,
    CancelTwoTone,
    CheckCircleOutlined,
    CheckCircleTwoTone, CircleOutlined, DoubleArrowOutlined, FeedbackOutlined,
    InfoOutlined, ReadMoreOutlined, SendOutlined
} from "@mui/icons-material"

//router
import { useNavigate, useParams } from "react-router-dom"

//apollo
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import {
    CURRENT_CLIENT,
    QUESTIONNAIRE_NEXT_QUESTION,
    CREATE_FEEDBACK,
    CREATE_ANSWER_EXAM_TRAINING
} from "../../queries/auth/userExamQueries"
import { LOAD_QUESTIONNAIRE_DETAILS } from "../../queries/admin/questionnaireQueries"

//components
import Timer from "../../components/Timer"
import { ErrorNotification, SuccessNotification } from "../../components/Notifications/Notifications"

//redux
import { useSelector } from "react-redux"

//formik
import { useFormik } from "formik"
import * as Yup from "yup"

//styles
import userExamPageStyles from "../../assets/styles/userExamPageStyles"
import quizLayoutStyles from "../../assets/styles/quizLayoutStyles"
import IconButton from "@mui/material/IconButton"

export default function UserTrainingExam() {
    const navigate = useNavigate()
    const authUser = useSelector((state) => state.loginReducer)

    const { questionnaireId, trainingId } = useParams()

    const notifyError = message => ErrorNotification(message)
    const notifySuccess = message => SuccessNotification(message)

    const [questionnaireDescription, setQuestionnaireDescription] = React.useState("")
    const [questionnaireTopic, setQuestionnaireTopic] = React.useState("")
    const [questionnaireTime, setQuestionnaireTime] = React.useState(0)

    const { loading: loadingQuestionnaireData } = useQuery(
        LOAD_QUESTIONNAIRE_DETAILS,
        {
            variables: {id: questionnaireId},
            onCompleted: data => {
                if (data?.questionnaire?.id) {
                    setQuestionnaireDescription(data.questionnaire.description)
                    setQuestionnaireTopic(data.questionnaire.topic.name)
                    setQuestionnaireTime(data.questionnaire.time)
                }
            }
        }
    )

    const [loadClient] = useLazyQuery(
        CURRENT_CLIENT,
        {
            variables: {
                id: authUser.client.id
            },
            fetchPolicy: "network-only"
        }
    )

    const [question, setQuestion] = React.useState(null)
    const [questionnaire, setQuestionnaire] = React.useState(null)

    const [displayReadMoreSection, setDisplayReadMoreSection] = React.useState(false)
    const [displayFeedBackSection, setDisplayFeedBackSection] = React.useState(false)

    const [loadNextQuestion, { loading }] =
        useLazyQuery(
            QUESTIONNAIRE_NEXT_QUESTION,
            {
                fetchPolicy: "network-only",
                onCompleted: response => {
                    if (response?.nextQuestion?.reason === "ok") {
                        setQuestionnaire(
                            {
                                reason: response.nextQuestion.reason,
                                seconds: response.nextQuestion.seconds,
                                paginationSize: response.nextQuestion.paginationSize,
                                paginationItemNo: response.nextQuestion.paginationItemNo,
                                paginationHasPrevious: response.nextQuestion.paginationHasPrevious,
                                paginationHasNext: response.nextQuestion.paginationHasNext,
                            }
                        )

                        const possibleAnswers = response
                            .nextQuestion
                            .questionnaireQuestion
                            .question
                            .questionPossibleAnswer
                            .edges
                            .map(item => ({...item.node}))

                        setQuestion(
                            {
                                questionnaireQuestionId: response.nextQuestion.questionnaireQuestion.id,
                                ...response.nextQuestion.questionnaireQuestion.question,
                                questionPossibleAnswer: possibleAnswers,
                                selectedAnswer: "",
                            }
                        )

                        setDisplayReadMoreSection(false)
                        setDisplayFeedBackSection(false)
                    }
                }
            }
        )

    //to initialize questionnaire and load first question
    React.useEffect(() => {
        loadClient()
            .then(async response => {
                let data = response.data
                if (data?.client?.id) {
                    if (data.client.currentExamIdCodified === trainingId) {
                        await loadNextQuestion({ variables: { clientId: authUser.client.id } })
                    } else {
                        notifyError("Requested exam isn't available any more. Try to start a new exam")
                        navigate(`/quiz/questionnaire/${questionnaireId}`)
                    }
                } else {
                    notifyError("Fetch data from client failed, try again")
                }
            })
        // eslint-disable-next-line
    }, [])

    //to load next question
    async function handleNextQuestion() {
        if (questionnaire.paginationHasNext) {
            await loadNextQuestion({variables: {clientId: authUser.client.id}})
        } else {
            loadNextQuestion({variables: {clientId: authUser.client.id}})
                .then(() => {navigate(`/quiz/questionnaire/${questionnaireId}/training/${trainingId}/summary`)})
        }
    }

    const [createAnswer] = useMutation(CREATE_ANSWER_EXAM_TRAINING)
    const [createFeedback, {loading: loadingCreateFeedback}] = useMutation(CREATE_FEEDBACK)

    const validationSchema = Yup.object({
        question: Yup.object({
            id: Yup.string(),
            topic: Yup.object({
                id: Yup.string(),
                name: Yup.string(),
                description: Yup.string()
            }),
            description: Yup.string(),
            explanation: Yup.string(),
            questionPossibleAnswer: Yup.array(
                Yup.object({
                    id: Yup.string(),
                    description: Yup.string(),
                    isCorrect: Yup.boolean()
                })
            ),
            selectedAnswer: Yup
                .string()
                .required()
                .oneOf(question?.questionPossibleAnswer.filter(item => item.isCorrect).map(item => item.id) ?? [], "Incorrect answer"),
        }),
        feedback: Yup.string()
    })

    const formik = useFormik({
        initialValues: {
            question: question,
            feedback: "",
        },
        validationSchema: validationSchema,
        enableReinitialize: true
    })

    const TableHeaderBar = () => {
        return (
            <AppBar position="static" color={"transparent"}>
                <Toolbar>
                    <Grid container>
                        <Grid item xs={true}>
                            <Typography
                                variant="h6"
                                noWrap
                                sx={{
                                    mr: 2,
                                    fontFamily: 'monospace',
                                    fontWeight: 700,
                                    letterSpacing: '.3rem',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                }}
                            >
                                Train Quiz
                            </Typography>
                        </Grid>
                        <Grid item xs={"auto"}>
                            {
                                (loading || loadingQuestionnaireData) && <CircularProgress color={"inherit"} size={24}/>
                            }
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        );
    }

    const optionLetters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S",
        "T", "U", "V", "W", "X", "Y", "Z"]

    function handleStopQuiz() {
        navigate(`/quiz/questionnaire/${questionnaireId}`)
    }

    function handleDisplaySection(section) {
        if (section === "read more") {
            setDisplayFeedBackSection(false)
            setDisplayReadMoreSection(!displayReadMoreSection)
        } else {
            setDisplayReadMoreSection(false)
            setDisplayFeedBackSection(!displayFeedBackSection)
        }
    }

    function handleSelectAnswer(item) {
        if (!formik.values.question?.selectedAnswer) {
            createAnswer({
                variables: {
                    exam: trainingId,
                    questionnaireQuestion: question.questionnaireQuestionId,
                    possibleAnswer: item.id,
                }
            }).then(response => {
                if (response?.data?.createAnswer?.answer?.id) {
                    formik.setFieldValue("question.selectedAnswer", item.id)
                } else {
                    notifyError("Save selected answer failed, try again")
                }
            }).catch(() => {
                notifyError("Something were wrong while saving selected answer")
            })
        }
    }

    function createFeedbackHandler() {
        if (formik.values.feedback) {
            createFeedback({variables: {
                    questionId: question.id,
                    clientId: authUser.client.id,
                    feedback: formik.values.feedback
            }})
                .then(response => {
                    if (response?.data?.createFeedback?.feedback?.id) {
                        notifySuccess("Feedback created for question. Thanks!")
                        formik.setFieldValue("feedback",  "")
                    } else {
                        notifyError("Feedback creation failed, try again")
                    }
                })
                .catch(() => {
                    notifyError("Feedback creation failed, try again")
                })
        } else {
            formik.setFieldError("feedback", "field required")
        }
    }

    return (
        <Box sx={{ width: "100%" }}>
            <Card variant={"outlined"} sx={quizLayoutStyles.componentCardContainer}>
                <TableHeaderBar />
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} container>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2">
                                    {questionnaireDescription}
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="caption" color={"gray"}>
                                    {questionnaireTopic}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid container item xs={12} spacing={1}>
                                <Grid container item xs={true} spacing={1}>
                                    <Grid
                                        item
                                        xs={"auto"}
                                        container
                                        alignItems={"center"}
                                        sx={{ fontSize: "18px", fontWeight: 600 }}
                                    >
                                        {questionnaire?.paginationItemNo}
                                        <span style={{ margin: "0 4px" }}>of</span>
                                        {questionnaire?.paginationSize}
                                    </Grid>
                                </Grid>

                                <Grid container item xs={"auto"} alignItems={"center"}>
                                    {questionnaire && (
                                        <Timer
                                            duration={questionnaireTime}
                                            initialRemainingTime={
                                                questionnaireTime > questionnaire.seconds
                                                    ? questionnaireTime - questionnaire.seconds
                                                    : 0
                                            }
                                            inTime={questionnaireTime > questionnaire.seconds}
                                        />
                                    )}
                                </Grid>
                            </Grid>

                            <Grid container item xs={12}>
                                <Divider flexItem sx={{ width: 1 }} />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant={"subtitle2"} sx={{ pl: "6px", pr: "6px" }}>
                                    {formik.values.question?.description}
                                </Typography>
                            </Grid>

                            <Grid container item xs={12} spacing={1}>
                                {formik.values.question?.questionPossibleAnswer.map((item, index) => (
                                    <Grid
                                        key={item.id}
                                        container
                                        item
                                        xs={12}
                                        sx={userExamPageStyles.answerOption}
                                        style={
                                            formik.values.question?.selectedAnswer === item.id
                                                ? { background: "rgba(53,197,60,0.7)" }
                                                : null
                                        }
                                        onClick={() => handleSelectAnswer(item)}
                                    >
                                        <Grid item xs={"auto"} sx={{ mr: 1 }}>
                                            <Avatar
                                                sx={{ width: 30, height: 30 }}
                                                style={
                                                    formik.values.question?.selectedAnswer === item.id
                                                        ? { background: "rgba(25,118,210,0.8)" }
                                                        : null
                                                }
                                            >
                                                {optionLetters[index % 26]}
                                            </Avatar>
                                        </Grid>
                                        <Grid item xs={true}>
                                            <Typography component={"div"} variant={"body1"}>
                                                {item.description}
                                            </Typography>
                                        </Grid>
                                        {formik.values.question?.selectedAnswer && (
                                            <Grid item xs={"auto"} sx={{ ml: 1 }}>
                                                <Fade in>
                                                    {item.isCorrect ? (
                                                        <CheckCircleOutlined
                                                            color={
                                                                formik.values.question?.selectedAnswer === item.id
                                                                    ? "success"
                                                                    : "disabled"
                                                            }
                                                        />
                                                    ) : (
                                                        <CancelOutlined
                                                            color={
                                                                formik.values.question?.selectedAnswer === item.id
                                                                    ? "error"
                                                                    : "disabled"
                                                            }
                                                        />
                                                    )}
                                                </Fade>
                                            </Grid>
                                        )}
                                    </Grid>
                                ))}
                            </Grid>

                            <Grid container item xs={12}>
                                <Divider flexItem sx={{ width: 1 }} />
                            </Grid>

                            {formik.values.question?.selectedAnswer && (
                                <Fade in>
                                    <Grid container item xs={12} spacing={1}>
                                        <Grid item xs={12}>
                                            <Grid
                                                container
                                                justifyContent={"center"}
                                                sx={{
                                                    fontSize: "20px",
                                                    color: formik.errors?.question?.selectedAnswer
                                                        ? "error.main"
                                                        : "success.main",
                                                }}
                                            >
                                                <Grid container item xs={"auto"} alignItems={"center"} sx={{ mr: 1 }}>
                                                    {formik.errors?.question?.selectedAnswer ? (
                                                        <CancelTwoTone />
                                                    ) : (
                                                        <CheckCircleTwoTone />
                                                    )}
                                                </Grid>
                                                <Grid container item xs={"auto"} alignItems={"center"}>
                                                    <Typography variant={"h5"} sx={{ fontSize: "18px" }}>
                                                        {formik.errors?.question?.selectedAnswer
                                                            ? "Your answer was incorrect"
                                                            : "Great answer"}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid container item xs={12} sx={{ mt: 1 }}>
                                            <Divider flexItem sx={{ width: 1 }} />
                                        </Grid>

                                        <Grid container item xs={12}>
                                            <Grid item xs={12}>
                                                <InfoOutlined  fontSize='small'/>
                                            </Grid>
                                            <Grid item xs={12} sx={{wordWrap: "balance"}}>
                                                {formik.values.question?.explanation}
                                            </Grid>
                                        </Grid>

                                        <Grid container item xs={12} spacing={1} mt={1}>
                                            {(formik.values.question?.questionUrl?.edges?.length > 0 ||
                                                formik.values.question?.questionCite?.edges?.length > 0) && (
                                                <Grid container item xs={12} sm={6} justifyContent={"center"}>
                                                    <Button
                                                        color={"inherit"}
                                                        variant={"outlined"}
                                                        size={"small"}
                                                        sx={{ mr: 1 }}
                                                        startIcon={<ReadMoreOutlined />}
                                                        onClick={() => handleDisplaySection("read more")}
                                                    >
                                                        Documentation
                                                    </Button>
                                                </Grid>
                                            )}

                                            <Grid container item xs={12} sm={6} justifyContent={"center"}>
                                                <Button
                                                    color={"inherit"}
                                                    variant={"outlined"}
                                                    size={"small"}
                                                    startIcon={<FeedbackOutlined />}
                                                    onClick={() => handleDisplaySection("feedback")}
                                                >
                                                    Leave a feedback
                                                </Button>
                                            </Grid>

                                            <Grid container item xs={12}>
                                                {displayReadMoreSection && (
                                                    <>
                                                        <Grid item xs={12} md={6}>
                                                            {formik.values.question?.questionUrl?.edges?.map(
                                                                (urlItem) => (
                                                                    <Grid container spacing={1} key={urlItem.node.id}>
                                                                        <a href={urlItem.node.url}>
                                                                            <Grid container spacing={1}>
                                                                                <Grid
                                                                                    container
                                                                                    item
                                                                                    xs={"auto"}
                                                                                    alignItems={"center"}
                                                                                >
                                                                                    <DoubleArrowOutlined sx={{ fontSize: "14px" }}/>
                                                                                </Grid>
                                                                                <Grid
                                                                                    container
                                                                                    item
                                                                                    xs={true}
                                                                                    alignItems={"center"}
                                                                                >
                                                                                    {urlItem.node.url}
                                                                                </Grid>
                                                                            </Grid>
                                                                        </a>
                                                                    </Grid>
                                                                )
                                                            )}
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            {formik.values.question?.questionCite?.edges?.map(
                                                                (citeItem) => (
                                                                    <Grid container spacing={1} key={citeItem.node.id}>
                                                                        <Grid
                                                                            container
                                                                            item
                                                                            xs={"auto"}
                                                                            alignItems={"center"}
                                                                        >
                                                                            <CircleOutlined sx={{ fontSize: "8px" }} />
                                                                        </Grid>
                                                                        <Grid
                                                                            container
                                                                            item
                                                                            xs={true}
                                                                            alignItems={"center"}
                                                                        >
                                                                            {citeItem.node.cite}
                                                                        </Grid>
                                                                    </Grid>
                                                                )
                                                            )}
                                                        </Grid>
                                                    </>
                                                )}

                                                {displayFeedBackSection && (
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography variant={"body1"}>
                                                                If you have any comments or suggestions about this
                                                                questions or its related information, you can do it
                                                                here. We appreciate any help.
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={10} lg={9}>
                                                            <TextField
                                                                name={"feedback"}
                                                                label="Feedback"
                                                                variant={"filled"}
                                                                size={"small"}
                                                                fullWidth
                                                                {...formik.getFieldProps("feedback")}
                                                                error={
                                                                    formik.errors.feedback && formik.touched.feedback
                                                                }
                                                                helperText={
                                                                    formik.errors.feedback &&
                                                                    formik.touched.feedback &&
                                                                    formik.errors.feedback
                                                                }
                                                                InputProps={{
                                                                    endAdornment: loadingCreateFeedback ? (
                                                                        <CircularProgress size={22} />
                                                                    ) : (
                                                                        <InputAdornment
                                                                            position="end"
                                                                            sx={{ mr: "6px" }}
                                                                        >
                                                                            <IconButton
                                                                                color={"primary"}
                                                                                size={"small"}
                                                                            >
                                                                                <SendOutlined fontSize={"small"} />
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                onClick={createFeedbackHandler}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Fade>
                            )}
                        </Grid>
                    </Grid>

                    <Box sx={{ display: "flex", justifyContent: "right", p: 1, m: 1 }}>
                        <Button
                            color={"secondary"}
                            variant={"contained"}
                            size={"small"}
                            sx={{ m: 1 }}
                            onClick={handleStopQuiz}
                        >
                            Leave quiz
                        </Button>

                        <Button
                            color={"primary"}
                            variant={"contained"}
                            size={"small"}
                            sx={{ m: 1 }}
                            onClick={handleNextQuestion}
                            disabled={!formik.values.question?.selectedAnswer || loading}
                        >
                            {questionnaire?.paginationHasNext ? "Next" : "Finish"}
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    )
}