import * as React from "react"

//material
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { Card, CardContent, Grid, Button } from "@mui/material"
import { LoadingButton } from "@mui/lab"

//router
import { useNavigate, useParams } from "react-router-dom"

//apollo
import { useMutation, useQuery } from "@apollo/client"
import { LOAD_QUESTIONNAIRE_DETAILS } from "../../queries/admin/questionnaireQueries"
import { INITIALIZE_EXAM } from "../../queries/auth/userExamQueries"

//redux
import { useSelector } from "react-redux"

//components
import { ErrorNotification } from "../../components/Notifications/Notifications"
import { LoadingSkeleton } from "../../utils/tableUtils"

//date
import { formatDuration, intervalToDuration } from "date-fns"

//styles
import quizLayoutStyles from "../../assets/styles/quizLayoutStyles"

export default function UserExamStart(props) {
    const navigate = useNavigate()

    const { questionnaireId } = useParams()
    const authUser = useSelector((state) => state.loginReducer)

    const notifyError = (message) => ErrorNotification(message)

    const { data, loading } = useQuery(LOAD_QUESTIONNAIRE_DETAILS, { variables: { id: questionnaireId } })
    const [initializeExam, { loading: loadingInitializeExam }] = useMutation(INITIALIZE_EXAM)

    const TableHeaderBar = () => {
        return (
            <AppBar position="static" color={"transparent"}>
                <Toolbar sx={{ display: "flex", justifyContent: "space-between", overflowX: "auto" }}>
                    <Box>
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{
                                mr: 2,
                                fontFamily: "monospace",
                                fontWeight: 700,
                                letterSpacing: ".3rem",
                                color: "inherit",
                                textDecoration: "none",
                            }}
                        >
                            Quiz details
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
        )
    }

    function handleStartExam(examPurposeId, purpose) {
        initializeExam({
            variables: {
                clientId: authUser.client.id,
                questionnaireId: questionnaireId,
                examPurpose: examPurposeId,
            },
        })
            .then((response) => {
                if (response?.data?.createExam?.exam?.id) {
                    if (purpose === "Test") {
                        navigate(`/quiz/questionnaire/${questionnaireId}/exam/${response.data.createExam.exam.id}`)
                    } else {
                        navigate(`/quiz/questionnaire/${questionnaireId}/training/${response.data.createExam.exam.id}`)
                    }
                } else {
                    notifyError("Something was wrong while exam initialization, try again")
                }
            })
            .catch((e) => {
                if (e.graphQLErrors && e.graphQLErrors[0]?.message === "Not authorized, you must pay") {
                    notifyError("You must pay subscription to be able of use all our services")
                } else notifyError("Something was wrong while training initialization, try again")
            })
    }

    return (
        <Box sx={{ width: "100%" }}>
            <Card variant={"outlined"} sx={quizLayoutStyles.componentCardContainer}>
                <TableHeaderBar />
                <CardContent>
                    {loading ? (
                        <LoadingSkeleton />
                    ) : (
                        <>
                            <Grid container spacing={1}>
                                <Grid container item xs={12} spacing={1}>
                                    <Grid item xs={12}>
                                        {data?.questionnaire?.description ?? "-"}
                                    </Grid>

                                    <Grid container item xs={12} spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle2">
                                                Topic: {data?.questionnaire?.topic?.name ?? "-"}
                                            </Typography>
                                        </Grid>

                                        {data?.questionnaire?.topic?.description && (
                                            <Grid item xs={12}>
                                                <Typography variant="caption">
                                                    {data?.questionnaire?.topic?.description}
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography variant={"subtitle2"}>
                                            Time:{" "}
                                            {data?.questionnaire?.time
                                                ? formatDuration(
                                                      intervalToDuration({
                                                          start: 0,
                                                          end: data.questionnaire.time * 1000,
                                                      })
                                                  )
                                                : "-"}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography variant={"subtitle2"}>
                                            Questions:{" "}
                                            {data?.questionnaire?.questionnaireQuestionnaireQuestion?.edges.length ??
                                                "-"}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12}>
                                    <Typography variant={"body1"} sx={{ wordWrap: "balance" }}>
                                        Press start button when you ready to start quiz. Once the exam has started you
                                        need to keep an eye on the time remaining on the clock. You can always finish
                                        answering all the questions even if time runs out. Test your knowledge and Good
                                        luck.
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Box sx={{ display: "flex", justifyContent: "right", p: 1, m: 1 }}>
                                <Grid container>
                                    <Grid item xs={6} container alignItems={"center"} justifyContent={"center"}>
                                        <Button
                                            color={"secondary"}
                                            variant={"contained"}
                                            size={"small"}
                                            sx={{ m: 1 }}
                                            onClick={() => navigate("/quiz/questionnaire")}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>

                                    <Grid item xs={6} container justifyContent={"center"}>
                                        {data?.questionnaire?.examPurpose &&
                                            data.questionnaire.examPurpose.edges.find(
                                                (item) => item.node.name.toUpperCase() === "TRAIN"
                                            ) && (
                                                <LoadingButton
                                                    color={"primary"}
                                                    type="submit"
                                                    variant={"outlined"}
                                                    size={"small"}
                                                    sx={{ m: 1 }}
                                                    loading={loadingInitializeExam}
                                                    onClick={() =>
                                                        handleStartExam(
                                                            data.questionnaire.examPurpose.edges.find(
                                                                (item) => item.node.name.toUpperCase() === "TRAIN"
                                                            ).node.id,
                                                            "Train"
                                                        )
                                                    }
                                                >
                                                    Start train
                                                </LoadingButton>
                                            )}

                                        {data?.questionnaire?.examPurpose &&
                                            data.questionnaire.examPurpose.edges.find(
                                                (item) => item.node.name.toUpperCase() === "TEST"
                                            ) && (
                                                <LoadingButton
                                                    color={"primary"}
                                                    type="submit"
                                                    variant={"contained"}
                                                    size={"small"}
                                                    sx={{ m: 1 }}
                                                    loading={loadingInitializeExam}
                                                    onClick={() =>
                                                        handleStartExam(
                                                            data.questionnaire.examPurpose.edges.find(
                                                                (item) => item.node.name.toUpperCase() === "TEST"
                                                            ).node.id,
                                                            "Test"
                                                        )
                                                    }
                                                >
                                                    Start test
                                                </LoadingButton>
                                            )}
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    )}
                </CardContent>
            </Card>
        </Box>
    )
}
