//apollo
import { ApolloClient, InMemoryCache, from } from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import { setContext } from "@apollo/client/link/context"
import { createUploadLink } from "apollo-upload-client"

//manage browser cookies
import Cookies from "js-cookie"
import { ErrorNotification } from "../components/Notifications/Notifications"

const notifyError = message => ErrorNotification(message)

const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
        for (const { message } of graphQLErrors) {
            if (message === "You do not have permission to perform this action") {
                Cookies.remove("JWT")
                client.clearStore()
                notifyError("Credentials validation error. Re authentication needed")
                setTimeout(() => {
                    window.open("/", "_self")
                }, 2500)
            }
        }
    }
})

const authLink = setContext((_, { headers }) => {
    const token = Cookies.get("JWT")

    return {
        headers: {
            ...headers,
            authorization: token ? `JWT ${token}` : "",
        },
    }
})

const httpLink = createUploadLink({
    uri: `${process.env.REACT_APP_API_URL}graphql/`,
})

export const client = new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache(),
})
