import {
    Avatar,
    Box,
    Button,
    Card,
    Divider,
    Fade,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography
} from "@mui/material"
import {
    ChevronLeft,
    ChevronRight,
    InfoOutlined,
    KeyboardDoubleArrowRightOutlined,
    PersonOutlined,
    VisibilityOffOutlined,
    VisibilityOutlined
} from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import loginPageStyles from "../../assets/styles/loginPageStyles"
import React from "react"

//forms
import { Formik } from "formik"
import * as Yup from 'yup';

//apollo
import { useMutation } from "@apollo/client";
import { LOGIN } from "../../queries/auth/loginQueries"

//router
import { NavLink, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

//action
import { singIn } from "../../actions/auth/loginActions"

//browser cookies
import Cookies from "js-cookie"
import { USER_REGISTER } from "../../queries/auth/userQueries"

//notification
import { ErrorNotification, SuccessNotification } from "../../components/Notifications/Notifications"

import logo from "../../assets/images/logo200.png"


export default function LoginPage(props) {

    const [showSignUpForm, setShowSignUpForm] = React.useState(false)

    const notifyError = message => ErrorNotification(message)
    const notifySuccess = message => SuccessNotification(message)

    const LoginForm = () => {
        const [ login, {loading} ] = useMutation(LOGIN)

        const navigate = useNavigate()
        const dispatch = useDispatch()

        const token = !!Cookies.get("JWT")
        const authUser = useSelector(state => state.loginReducer)

        React.useEffect(() => {
            if (token && authUser) {
                navigate("/quiz/dashboard")
            }
        })

        const [wrongLoginAttempts, setWrongLoginAttempts] = React.useState(0)

        const successForm = (response) => {
            dispatch(singIn({
                token: response.token,
                refreshToken: response.refreshToken,
                user: response.user
            }))
            navigate("/quiz/dashboard")

        }

        const initialValues = {
            username: "",
            password: ""
        }

        const validationSchema = Yup.object({
            username: Yup.string()
                .required('field required'),
            password: Yup.string()
                .required('field required')
        })

        const [showPassword, setShowPassword] = React.useState(false);
        const toggleVisibility = () => {
            setShowPassword(!showPassword);
        }

        return <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setFieldError }) => {
                login({variables: values})
                    .then(response => {
                        let loginResponse = response.data.tokenAuth
                        if (!loginResponse.success && loginResponse.errors) {
                            setFieldError("password", loginResponse.errors.nonFieldErrors[0].message)
                            setWrongLoginAttempts(wrongLoginAttempts + 1)
                        }

                        if (loginResponse.success) {
                            successForm(loginResponse)
                        }
                    })
            }}
        >
            {
                formik => (
                    <Grid container item xs={12}>
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        name="username"
                                        label="Username"
                                        size={"small"}
                                        fullWidth
                                        variant={"outlined"}
                                        {...formik.getFieldProps("username")}
                                        error={formik.errors.username && formik.touched.username}
                                        helperText={(formik.errors.username && formik.touched.username) && formik.errors.username}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end" sx={{mr: "6px"}}>
                                                    <PersonOutlined />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        name="password"
                                        label="Password"
                                        size={"small"}
                                        type={showPassword ? "text" : "password"}
                                        fullWidth
                                        variant={"outlined"}
                                        {...formik.getFieldProps("password")}
                                        error={formik.errors.password && formik.touched.password}
                                        helperText={(formik.errors.password && formik.touched.password) && formik.errors.password}
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment
                                                    position="end"
                                                    onClick={toggleVisibility}
                                                >
                                                    <IconButton size={"small"}>
                                                        {
                                                            showPassword
                                                                ? <VisibilityOffOutlined/>
                                                                : <VisibilityOutlined/>
                                                        }
                                                    </IconButton>
                                                </InputAdornment>
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <LoadingButton
                                        color={"primary"}
                                        type="submit"
                                        variant={"contained"}
                                        size={"large"}
                                        sx={{width: "100%"}}
                                        loading={loading}
                                    >
                                        Login
                                    </LoadingButton>
                                </Grid>

                                {
                                    wrongLoginAttempts >= 2
                                    && <Fade in={wrongLoginAttempts >= 2}>
                                        <Grid item xs={12}>
                                            Did you forget your password?
                                            <NavLink to="/account/recover-password">
                                                <Box sx={{color: "info.main", display: "inline-flex", ml: 1}}>
                                                    Recover it here
                                                </Box>
                                            </NavLink>
                                        </Grid>
                                    </Fade>
                                }
                            </Grid>
                        </form>
                    </Grid>
                )
            }
        </Formik>
    }

    const SignUpForm = () => {
        const [ registerUser, {loading} ] = useMutation(USER_REGISTER)

        const successForm = () => {
            notifySuccess("Registration succeeded. Check your mail and fallow the instructions to verify your account")
            setShowSignUpForm(false)
        }

        function getErrors(errors) {
            let errorsObject = {}
            Object.entries(errors).forEach(([field, fieldErrors]) => {
                if (field === "nonFieldErrors") {
                    fieldErrors.forEach(error => notifyError(error.message))
                } else {
                    let errorMessages = []
                    fieldErrors.forEach(error => errorMessages.push(error.message))
                    errorsObject[field] = errorMessages
                }
            })
            return errorsObject
        }

        const initialValues = {
            username: '',
            email: '',
            password1: '',
            password2: ''
        }

        const validationSchema = Yup.object({
            username: Yup.string()
                .required('field required')
                .min(6, 'username most contain 6 characters at least'),
            email: Yup.string()
                .required('field required')
                .email('email address is not valid'),
            password1: Yup.string()
                .required('field required')
                .min(8, 'most contain 8 characters at least'),
            password2: Yup.string()
                .required('field required')
                .when("password1", {
                    is: val => (!!(val && val.length > 0)),
                    then: Yup.string().oneOf(
                        [Yup.ref("password1")],
                        "passwords does not match"
                    )
                })
        })

        const [showPassword1, setShowPassword1] = React.useState(false);
        const [showPassword2, setShowPassword2] = React.useState(false);
        const toggleVisibilityPassword1 = () => {
            setShowPassword1(!showPassword1)
        }
        const toggleVisibilityPassword2 = () => {
            setShowPassword2(!showPassword2)
        }

        const [readAll, setReadAll] = React.useState(false)

        return <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(data, { setErrors }) => {
                registerUser({
                    variables: {
                        username: data.username,
                        email: data.email,
                        password1: data.password1,
                        password2: data.password2
                    }
                }).then(
                    (response) => {
                        let dataResponse = response.data.register

                        const errors = dataResponse.errors
                        if (errors) {
                            return setErrors(getErrors(errors))
                        }

                        if (dataResponse.success) {
                            return successForm()
                        }
                    }
                ).catch(() => {
                    notifyError("Register failed, try again")
                })
            }}
        >
            {
                formik => (
                    <Grid item xs={12}>
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        name="username"
                                        label="Username*"
                                        size={"small"}
                                        fullWidth
                                        variant={"outlined"}
                                        {...formik.getFieldProps("username")}
                                        error={formik.errors.username && formik.touched.username}
                                        helperText={(formik.errors.username && formik.touched.username) && formik.errors.username}
                                    />
                                </Grid>

                                <Grid container item xs={12} spacing={1}>
                                    <Grid container item xs={12} spacing={1}>
                                        <Grid container item xs={"auto"} alignItems={"center"}>
                                            <InfoOutlined fontSize={"small"} color={"warning"}/>
                                        </Grid>
                                        <Grid item xs={true}>
                                            {
                                                readAll
                                                    ? <Fade in>
                                                        <Typography variant={"caption"}>
                                                            Real State Quiz users must sing up in our system using the same email associated with the PayPal account with which they plan to pay their subscription. If you do not meet this requirement we will not be able to determine the payment of your subscription.
                                                        </Typography>
                                                    </Fade>
                                                    : <Typography variant={"caption"}>
                                                        Email usage limitation. Please read carefully here
                                                    </Typography>
                                            }
                                            <IconButton size={"small"} onClick={() => setReadAll(!readAll)}>
                                                {
                                                    readAll
                                                        ? <ChevronLeft fontSize={"small"}/>
                                                        : <ChevronRight fontSize={"small"}/>
                                                }
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="email"
                                            label="Email*"
                                            size={"small"}
                                            fullWidth
                                            variant={"outlined"}
                                            {...formik.getFieldProps("email")}
                                            error={formik.errors.email && formik.touched.email}
                                            helperText={(formik.errors.email && formik.touched.email) && formik.errors.email}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        name="password1"
                                        label="Password*"
                                        size={"small"}
                                        type={showPassword1 ? "text" : "password"}
                                        fullWidth
                                        variant={"outlined"}
                                        {...formik.getFieldProps("password1")}
                                        error={formik.errors.password1 && formik.touched.password1}
                                        helperText={(formik.errors.password1 && formik.touched.password1) && formik.errors.password1}
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment
                                                    position="end"
                                                    onClick={toggleVisibilityPassword1}
                                                >
                                                    <IconButton size={"small"}>
                                                        {showPassword1 ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                                                    </IconButton>
                                                </InputAdornment>,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        name="password2"
                                        label="Confirm Password*"
                                        size={"small"}
                                        type={showPassword2 ? "text" : "password"}
                                        fullWidth
                                        variant={"outlined"}
                                        {...formik.getFieldProps("password2")}
                                        error={formik.errors.password2 && formik.touched.password2}
                                        helperText={(formik.errors.password2 && formik.touched.password2) && formik.errors.password2}
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment
                                                    position="end"
                                                    onClick={toggleVisibilityPassword2}
                                                >
                                                    <IconButton size={"small"}>
                                                        {showPassword2 ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                                                    </IconButton>
                                                </InputAdornment>,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <LoadingButton
                                        color={"primary"}
                                        type="submit"
                                        variant={"contained"}
                                        size={"large"}
                                        sx={{width: "100%"}}
                                        loading={loading}
                                        disabled={!formik.isValid}
                                    >
                                        Create
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                )
            }
        </Formik>
    }

    return (
        <Grid container sx={loginPageStyles.container}>
            <Card variant={"elevation"} sx={loginPageStyles.card}>
                <Grid container sx={loginPageStyles.cardContainer}>
                    <Grid container item sx={loginPageStyles.leftColumnContainer}>
                        <Grid container item xs={12} justifyContent={"center"} alignItems={"center"}>
                            <Avatar
                                src={logo}
                                sx={{width: "200px", height: "200px"}}
                            />
                        </Grid>
                        <Grid container item xs={12} justifyContent={"center"} textAlign={"justify"}>
                            Online Quiz where you can test yourself simulating a real exam scenario.
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid container item xs={"auto"} alignItems={"center"} sx={{p: 1}}>
                                <KeyboardDoubleArrowRightOutlined />
                            </Grid>
                            <Grid container item xs={true} alignItems={"center"} sx={{p: 1}}>
                                <Typography variant={"body1"}>
                                    A lot of questions group by questionnaires
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid container item xs={"auto"} alignItems={"center"} sx={{p: 1}}>
                                <KeyboardDoubleArrowRightOutlined />
                            </Grid>
                            <Grid container item xs={true} alignItems={"center"} sx={{p: 1}}>
                                <Typography variant={"body1"}>
                                    Reference to study materials in each question
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid container item xs={"auto"} alignItems={"center"} sx={{p: 1}}>
                                <KeyboardDoubleArrowRightOutlined />
                            </Grid>
                            <Grid container item xs={true} alignItems={"center"} sx={{p: 1}}>
                                <Typography variant={"body1"}>
                                    Detailed stats over quiz started
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid container item xs={"auto"} alignItems={"center"} sx={{p: 1}}>
                                <KeyboardDoubleArrowRightOutlined />
                            </Grid>
                            <Grid container item xs={true} alignItems={"center"} sx={{p: 1}}>
                                <Typography variant={"body1"}>
                                    Economic subscription pay system
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid container item xs={"auto"} alignItems={"center"} sx={{p: 1}}>
                                <KeyboardDoubleArrowRightOutlined />
                            </Grid>
                            <Grid container item xs={true} alignItems={"center"} sx={{p: 1}}>
                                <Typography variant={"body1"}>
                                    Check our
                                    <NavLink to={"/usage-terms"} style={{marginLeft: "3px", color: "white", fontWeight: 600}}>
                                        Usage Terms here
                                    </NavLink>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container item sx={loginPageStyles.rightColumnContainer}>
                        <Grid container item alignItems={"center"} sx={{ width: "100%", m: 3}}>
                            <Button
                                color={"primary"}
                                variant={showSignUpForm ? "text" : "outlined"}
                                onClick={() => setShowSignUpForm(false)}
                                size={"small"}
                            >
                                Login
                            </Button>

                            <Divider
                                orientation={"vertical"}
                                sx={{
                                    ml: 1,
                                    mr: 1,
                                    display: "inline-flex",
                                    width: "0",
                                    height: "30px",
                                    border: 0,
                                    borderLeft: 2
                                }}
                            />

                            <Button
                                color={"secondary"}
                                variant={showSignUpForm ? "outlined" : "text"}
                                onClick={() => setShowSignUpForm(true)}
                                size={"small"}
                            >
                                Sign up
                            </Button>
                        </Grid>

                        { showSignUpForm ? <SignUpForm/> : <LoginForm /> }
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    )
}