import * as React from 'react';

//material
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {
    Button,
    Card,
    CardContent,
    Grid, InputAdornment,
    TextField
} from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material"

//router
import { useNavigate } from "react-router-dom"

//apollo
import { useMutation } from "@apollo/client"
import { PASSWORD_CHANGE } from "../../queries/auth/userQueries"

//formik
import { Formik } from "formik"
import * as Yup from "yup"

//components
import { SuccessNotification, ErrorNotification } from "../../components/Notifications/Notifications"

//styles
import quizLayoutStyles from "../../assets/styles/quizLayoutStyles"

export default function UserChangePasswordForm() {
    const navigate = useNavigate()

    const notifySuccess = () => SuccessNotification("Password change succeed")
    const notifyError = () => ErrorNotification("Password change failed")

    const [passwordChange, {loading}] = useMutation(PASSWORD_CHANGE)

    function getErrors(errors) {
        let errorsObject = {}
        Object.entries(errors).forEach(([field, fieldErrors]) => {
            if (field === "nonFieldErrors") {
                fieldErrors.forEach((error) => notifyError(error.message))
            } else {
                let errorMessages = []
                fieldErrors.forEach((error) => errorMessages.push(error.message))
                errorsObject[field] = errorMessages
            }
        })
        return errorsObject
    }

    const TableHeaderBar = () => {
        return (
            <AppBar position="static" color={"transparent"}>
                <Toolbar disableGutters sx={quizLayoutStyles.componentHeaderToolbar}>
                    <Grid container>
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{
                                mr: 2,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                            }}
                        >
                            Change password
                        </Typography>
                    </Grid>
                </Toolbar>
            </AppBar>
        );
    }

    const initialValues = {
        oldPassword: "",
        newPassword1: "",
        newPassword2: "",
    }

    const validationSchema = Yup.object({
        oldPassword: Yup.string().required(),
        newPassword1: Yup.string().required("type a new password"),
        newPassword2: Yup
            .string()
            .required("confirm new password")
            .when("newPassword1", {
                is: (val) => !!(val && val.length > 0),
                then: Yup.string().oneOf([Yup.ref("newPassword1")], "passwords does not match"),
            }),
    })

    const [showOldPassword, setShowOldPassword] = React.useState(false)
    const [showNewPassword1, setShowNewPassword1] = React.useState(false)
    const [showNewPassword2, setShowNewPassword2] = React.useState(false)
    const toggleVisibilityOldPassword = () => {
        setShowOldPassword(!showOldPassword)
    }
    const toggleVisibilityNewPassword1 = () => {
        setShowNewPassword1(!showNewPassword1)
    }
    const toggleVisibilityNewPassword2 = () => {
        setShowNewPassword2(!showNewPassword2)
    }

    return (
        <Box sx={{width: "100%"}}>
            <Card variant={"outlined"} sx={quizLayoutStyles.componentCardContainer}>
                <TableHeaderBar/>
                <CardContent>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        enableReinitialize
                        onSubmit={(data, { setErrors }) => {
                            passwordChange({
                                variables: {
                                    oldPassword: data.oldPassword,
                                    newPassword1: data.newPassword1,
                                    newPassword2: data.newPassword2,
                                }
                            }).then(response => {
                                if (response?.data?.passwordChange?.success) {
                                    notifySuccess()
                                    navigate("/quiz/profile")
                                } else {
                                    if (response?.data?.passwordChange?.errors) {
                                        setErrors(getErrors(response.data.passwordChange.errors))
                                    } else {
                                        notifyError()
                                    }
                                }
                            })
                        }}
                    >
                        {
                            formik => (
                                <form onSubmit={formik.handleSubmit}>
                                    <Box>
                                        <Grid container spacing={2}>
                                            <Grid container item xs={12} justifyContent={"center"}>
                                                <TextField
                                                    type={showOldPassword ? "text" : "password"}
                                                    name="oldPassword"
                                                    label="Old Password*"
                                                    variant={"filled"}
                                                    size={"small"}
                                                    {...formik.getFieldProps("oldPassword")}
                                                    error={formik.errors.oldPassword && formik.touched.oldPassword}
                                                    helperText={
                                                        formik.errors.oldPassword && formik.touched.oldPassword && formik.errors.oldPassword
                                                    }
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end" onClick={toggleVisibilityOldPassword}>
                                                                {showOldPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    sx={{width: "350px"}}
                                                />
                                            </Grid>

                                            <Grid container item xs={12} justifyContent={"center"}>
                                                <TextField
                                                    type={showNewPassword1 ? "text" : "password"}
                                                    name="newPassword1"
                                                    label="New Password*"
                                                    variant={"filled"}
                                                    size={"small"}
                                                    {...formik.getFieldProps("newPassword1")}
                                                    error={formik.errors.newPassword1 && formik.touched.newPassword1}
                                                    helperText={
                                                        formik.errors.newPassword1 && formik.touched.newPassword1 && formik.errors.newPassword1
                                                    }
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end" onClick={toggleVisibilityNewPassword1}>
                                                                {showNewPassword1 ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    sx={{width: "350px"}}
                                                />
                                            </Grid>

                                            <Grid container item xs={12} justifyContent={"center"}>
                                                <TextField
                                                    type={showNewPassword2 ? "text" : "password"}
                                                    name="newPassword2"
                                                    label="Confirm Password*"
                                                    variant={"filled"}
                                                    size={"small"}
                                                    {...formik.getFieldProps("newPassword2")}
                                                    error={formik.errors.newPassword2 && formik.touched.newPassword2}
                                                    helperText={
                                                        formik.errors.newPassword2 && formik.touched.newPassword2 && formik.errors.newPassword2
                                                    }
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end" onClick={toggleVisibilityNewPassword2}>
                                                                {showNewPassword2 ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    sx={{width: "350px"}}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Box sx={{display: "flex", justifyContent: "right", p: 1, m: 1}}>
                                            <Button
                                                color={"secondary"}
                                                variant={"contained"}
                                                size={"small"}
                                                sx={{m: 1}}
                                                onClick={() => navigate("/quiz/profile/form")}
                                            >
                                                Cancel
                                            </Button>

                                            <LoadingButton
                                                color={"primary"}
                                                type="submit"
                                                variant={"contained"}
                                                size={"small"}
                                                sx={{m: 1}}
                                                loading={loading}
                                            >
                                                Update
                                            </LoadingButton>
                                        </Box>
                                    </Box>
                                </form>
                            )
                        }
                    </Formik>
                </CardContent>
            </Card>
        </Box>
    )
}