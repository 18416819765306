const userExamPageStyles = {
    answerOption: {
        display: "flex",
        boxSizing: "border-box",
        alignItems: "center",
        border: "1px solid #3498db",
        borderRadius: "8px",
        background: "rgba(112,226,218,0.7)",
        transition: "background 0.4s",
        p: "5px",
        m: "5px",
        ":hover": {
            cursor: "pointer",
            background: "rgba(53,197,60,0.7)",
        },
        ":hover .MuiAvatar-circular": {
            background: "rgba(25,118,210,0.8)",
        }
    },
}

export default userExamPageStyles