import * as React from 'react';

//material
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {
    Card,
    CardContent,
    CircularProgress,
    Grid,
} from "@mui/material"

//router
import { useNavigate } from "react-router-dom"

//apollo
import { useQuery } from "@apollo/client"

//styles
import quizLayoutStyles from "../../assets/styles/quizLayoutStyles"
import { I_PAID } from "../../queries/auth/userSubscriptionsQueries"

//notifications
import { SuccessNotification } from "../../components/Notifications/Notifications"

export default function UserSubscriptionPaymentDone() {
    const navigate = useNavigate()

    const notifySuccess = (message) => SuccessNotification(message)

    const { loading } = useQuery(
        I_PAID,
        {
            onCompleted: data => {
                if (data.iPaid) {
                    notifySuccess("Payment has been made correctly")
                } else {
                    notifySuccess("Your payment has been recorded and will be verified at PayPal. Once we could verify it you can use our services")
                }

                navigate("/quiz/subscription")
            }
        }
    )

    const TableHeaderBar = () => {
        return (
            <AppBar position="static" color={"transparent"}>
                <Toolbar disableGutters sx={quizLayoutStyles.componentHeaderToolbar}>
                    <Grid container>
                        <Grid item xs={true}>
                            <Typography
                                variant="h6"
                                noWrap
                                sx={{
                                    mr: 2,
                                    fontFamily: 'monospace',
                                    fontWeight: 700,
                                    letterSpacing: '.3rem',
                                    color: 'inherit',
                                }}
                            >
                                Payment done
                            </Typography>
                        </Grid>
                        <Grid item xs={"auto"}>
                            {
                                loading && <CircularProgress size={24} />
                            }
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        );
    }

    return (
        <Box sx={{width: "100%"}}>
            <Card variant={"outlined"} sx={quizLayoutStyles.componentCardContainer}>
                <TableHeaderBar/>
                <CardContent>
                   <Grid container spacing={1}>
                       Payment done
                   </Grid>
                </CardContent>
            </Card>
        </Box>
    )
}